import { deleteTemplate, getTemplate, newTemplate, patchTemplate } from "../reqTemplate";

var ROUTE_URL = 'plan';

export const getAllPlan = async () => {
  return await getTemplate(ROUTE_URL);
}; 

export const newPlan = async (payload) => {
  return await newTemplate(ROUTE_URL, payload);
};

export const deletePlan = async (id) => {
  return await deleteTemplate(`/${ROUTE_URL}/${id}`);
};

export const patchPlan = async (id, payload) => {
  return await patchTemplate(`/${ROUTE_URL}/${id}`, payload);
};
