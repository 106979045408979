import * as React from "react";
import PropTypes from "prop-types";

import EditIcon from "@mui/icons-material/Edit";
import UploadIcon from "@mui/icons-material/Upload";

import { Typography, Modal, Box, Button, Divider, IconButton, TextField, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import MaskedInput from "react-text-mask";
import { CpfValidator } from "utils/cpfValidator";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  maxHeight: '90%',
  overflowY: 'auto',
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "20px",
};

const cpfMask = [/[0-9]/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/,];


export default function EditModal({ item, id, deleteItem, patchItem, treinos, planos }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [ErrorMessage, setErrorMessage] = useState();
  const [ErrorPassword, setErrorPassword] = useState();

  console.log('dentro EditModal', treinos, planos, item)

  const [trainingPlan, setTrainingPlan] = useState();
  const [plano, setPlano] = useState();

  useEffect(() => {
    setTrainingPlan(item.trainingPlanId)
    setPlano(item.planId)
  }, [])


  const changeTrainingPlan = (event) => {
    const selectedValues = Array.isArray(event.target.value) ? event.target.value.filter(value => value !== undefined) : event.target.value;
    console.log(selectedValues)
    setTrainingPlan(selectedValues);
  };

  const changePlano = (event) => {
    const selectedValues = Array.isArray(event.target.value) ? event.target.value.filter(value => value !== undefined) : event.target.value;
    console.log(selectedValues)
    setPlano(selectedValues);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const correctCPF = await validateDatas(data);

    if (correctCPF) {

      const payload = {
        cpf: removerMascara(data.get("cpf")),
        name: data.get("name"),
        phoneNumber: data.get("telefone"),
        email: data.get("email"),
        impediments: data.get("impedimentos"),
        trainingPlanId: trainingPlan,
        planId: plano,
      };

      if (data.get('senha')) {
        if (data.get('senha') === data.get('senha2')) {
          payload.password = data.get('senha')
          setErrorPassword('')
          patchItem(id, payload);
          return handleClose();
        } else {
          setErrorPassword('Confira as Senhas.')
        }
      } else {
        patchItem(id, payload);
        return handleClose();

      }
    }
    return true
  };

  function removerMascara(cpfMascarado) {
    return cpfMascarado.replace(/\D/g, '');
  }

  const validateDatas = async (data) => {
    var res = await CpfValidator(data.get('cpf'));

    if (res) {

      setErrorMessage()
      return true;

    } else {
      setErrorMessage("CPF inválido")
      return false

    }
  }

  const handleDeleteItem = async () => {
    deleteItem(id);
    return handleClose();
  };

  return (
    <div>
      <IconButton aria-label="delete" size="large" onClick={handleOpen}>
        <EditIcon fontSize="inherit" />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" align="center">
            Editar Alunos
          </Typography>
          <IconButton
            aria-label="fechar"
            size="large"
            onClick={handleClose}
            style={{ position: "absolute", top: "10px", right: "10px" }}
          >
            X
          </IconButton>
          <Divider />

          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }} align="center">
            <Box component="div" sx={{ mt: 1, mb: 4 }}>
              <TextField margin="normal" id="name" label="Nome" name="name" variant="outlined"
                required fullWidth autoFocus defaultValue={item.name} sx={{ mb: 1 }} />
              <MaskedInput mask={cpfMask} label={'CPF'} name="cpf"
                defaultValue={item.cpf}
                guide={false}
                render={(ref, props) => (
                  <TextField variant="outlined" required fullWidth inputRef={ref} {...props} helperText={ErrorMessage} error={ErrorMessage ? true : false} />
                )}
              />
              <TextField margin="normal" id="telefone" label="Telefone" name="telefone" variant="outlined"
                required fullWidth defaultValue={item.phoneNumber} />
              <TextField margin="normal" id="email" label="Email" name="email" variant="outlined"
                fullWidth defaultValue={item.email} />
              <TextField margin="normal" id="name" label="Impedimentos" name="impedimentos" variant="outlined"
                fullWidth defaultValue={item.impediments} />

              <FormControl fullWidth margin="normal">
                <InputLabel>Plano de Aula</InputLabel>
                <Select
                  onChange={changeTrainingPlan}
                  value={trainingPlan}
                  label='Plano de Aula'
                  required
                >
                  {treinos && treinos.map(grupo => (
                    <MenuItem value={grupo.id} key={grupo.id}>
                      {grupo.description}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth margin="normal">
                <InputLabel>Plano</InputLabel>
                <Select
                  onChange={changePlano}
                  value={plano}
                  label='Plano'
                  required
                >
                  {planos && planos.map(grupo => (
                    <MenuItem value={grupo.id} key={grupo.id}>
                      {grupo.description}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Divider>Alterar Senha</Divider>
              <Box sx={{ mb: 1 }}>
                <TextField margin="normal" id="senha" label="Senha" name="senha" type="password"
                  helperText={ErrorPassword} error={ErrorPassword ? true : false} fullWidth />
              </Box>
              <Box sx={{ mb: 1 }}>
                <TextField margin="normal" id="senha2" label="Repita a Senha" name="senha2" type="password"
                  helperText={ErrorPassword} error={ErrorPassword ? true : false} fullWidth />
              </Box>

            </Box>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 2, mb: 2, bgcolor: "primary.main" }}
            >
              Editar
            </Button>
            <Button
              fullWidth
              variant="contained"
              color="error"
              sx={{ mt: 2, mb: 2 }}
              onClick={handleDeleteItem}
            >
              Deletar
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

EditModal.propTypes = {
  name: PropTypes.string.isRequired,
  image: PropTypes.string,
  id: PropTypes.number.isRequired,
  deleteItem: PropTypes.func.isRequired,
  patchItem: PropTypes.func.isRequired,
};
