import * as React from "react";
import { Divider, Typography, Modal, Box, Button, TextField, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import UploadIcon from "@mui/icons-material/Upload";
import PropTypes from "prop-types";
import MaskedInput from "react-text-mask";
import { CpfValidator } from "utils/cpfValidator";
import { useState } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  maxHeight: '90%', 
  overflowY: 'auto', 
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "20px",
};

const cpfMask = [/[0-9]/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/,];

export default function NewModal(props) {
  const [open, setOpen] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState();
  const handleClose = () => setOpen(false);
  const lightColor = "rgba(255, 255, 255, 0.7)";

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const correctCPF = await validateDatas(data);

    if (correctCPF) {

      const payload = {
        name: data.get("name"),
        cpf: removerMascara(data.get("cpf")),
        phoneNumber: data.get("telefone"),
        password: data.get("senha"),
      };
      
      console.log(payload)
      props.function(payload);
      return handleClose();
    }
    return true
  };

  function removerMascara(cpfMascarado) {
    return cpfMascarado.replace(/\D/g, '');
  }

  const validateDatas = async (data) => {
    var res = await CpfValidator(data.get('cpf'));

    if (res) {

      setErrorMessage()
      return true;

    } else {
        setErrorMessage("CPF inválido")
        return false

    }
  }

  return (
    <div>
      <Button
        variant="outlined"
        color="inherit"
        sx={{ borderColor: lightColor }}
        startIcon={<AddIcon />}
        onClick={handleOpen}
        spacing={2}
      >
        Adicionar
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" align="center">
            Adicionar Treinadores
          </Typography>
          <IconButton
            aria-label="fechar"
            size="large"
            onClick={handleClose}
            style={{ position: "absolute", top: "10px", right: "10px" }}
          >
            X
          </IconButton>
          <Divider />
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }} align="center">

            <Box sx={{ mt: 1, mb: 1 }}>
              <TextField margin="normal" id="name" label="Nome" name="name" required fullWidth />
            </Box>

            <Box sx={{ mb: 1 }}>
              <MaskedInput mask={cpfMask} label={'CPF'} name="cpf"
                guide={false}
                render={(ref, props) => (
                    <TextField autoFocus variant="outlined" required fullWidth inputRef={ref} {...props} helperText={ErrorMessage} error={ErrorMessage ? true : false}/>
                )}
              />
            </Box>

            <Box sx={{ mb: 1 }}>
              <TextField margin="normal" id="telefone" label="Telefone" name="telefone" type="number"
                required fullWidth />
            </Box>

            <Box sx={{ mb: 1 }}>
              <TextField margin="normal" id="senha" label="Senha" name="senha" type="text"
                required fullWidth />
            </Box>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 2, mb: 2, bgcolor: "primary.main" }}
            >
              Adicionar
            </Button>

          </Box>
        </Box>
      </Modal>
    </div>
  );
}

NewModal.propTypes = {
  function: PropTypes.func.isRequired,
};
