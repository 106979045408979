import React from 'react'

import { AlertTitle, IconButton, Stack, Alert } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { Link } from 'react-router-dom';

const TreinosCard = ({workout}) => {

  return (

    <Stack sx={{ width: '100%', boxShadow: 2, cursor:'pointer', borderRadius:'15px' }} spacing={2} mb={2}>

        <Link to={'/private/aluno/exercise/'+workout.id}>

            <Alert variant="filled" color="primary" action={
                <IconButton aria-label="close" color="inherit" size="small">
                    <SendIcon />
                </IconButton>
            }> 
                <AlertTitle> {workout.description} </AlertTitle>
            </Alert>

        </Link>

    </Stack>

  )
}

export default TreinosCard
