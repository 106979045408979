import { postLogin } from "api/auth/hooks/useLogin";
import { createContext } from "react";
import { encryptCryptoJs } from "utils/crypto";

const AuthContext = createContext();

// eslint-disable-next-line react/prop-types
export const AuthContextProvider = ({ children }) => {

  const login = async (payload) => {

    const resLogin = await postLogin(payload);

    return resLogin;
    
  };

  const logout = () => {
    localStorage.clear();
  };

  return <AuthContext.Provider value={{ login, logout }}>{children}</AuthContext.Provider>;
};

export default AuthContext;
