/* eslint-disable no-unused-vars */
import React from "react";
import { Box, Card, CardContent, CardMedia, Typography } from "@mui/material";
//import EditModal from "./EditModal";

const ItemCardAvisos = ({ title, imageUrl, children }) => {

  return (
    <Card sx={{ mb: 2 }}>
      <CardContent style={{ display: "flex", alignItems: "center" }}>
        {imageUrl && (imageUrl.startsWith("data:image")) ? (
              <img alt='gif' src={imageUrl} style={{ width: "70px", height: "70px", borderRadius: "10px" }}></img>
            ) : (
              <video  playsinline src={imageUrl} style={{ width: "70px", height: "70px", borderRadius: "10px" }} 
                autoPlay={true} loop={true} muted={true}></video>
            )}
        <Box sx={{ ml: 2 }}>
          <Typography variant="h5">{title ? title : "Avisos não Encontrados"}</Typography>
        </Box>

        <div style={{ marginLeft: "auto" }}>{children}</div>
      </CardContent>
    </Card>
  );
};

export { ItemCardAvisos };
