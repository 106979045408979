/* eslint-disable no-unused-vars */
import React from "react";
import { Box, Card, CardContent, CardMedia, Typography } from "@mui/material";
//import EditModal from "./EditModal";

const ItemCardExercicios = ({ description, imageUrl, children }) => {

  return (
    <Card sx={{ mb: 2 }}>
      <CardContent style={{ display: "flex", alignItems: "center" }}>
        {imageUrl && (
          <div style={{ marginRight: "20px" }}>
            <CardMedia
              component="img"
              alt="Imagem"
              sx={{ width: "70px", height: "70px", borderRadius: "10px" }}
              image={imageUrl}
            />
          </div>
        )}
        <Box sx={{ ml: 2 }}>
          <Typography variant="h5">{description ? description : "Exercícios não Encontrado"}</Typography>
        </Box>

        <div style={{ marginLeft: "auto" }}>{children}</div>
      </CardContent>
    </Card>
  );
};

export { ItemCardExercicios };
