import { deleteTemplate, getByIdTemplate, getTemplate, newTemplate, patchTemplate, postAutocompleteTemplate, postWithoutMediaTemplate } from "../reqTemplate";

var ROUTE_URL = 'workout';

export const getAllWorkout = async () => {
  return await getTemplate(ROUTE_URL);
}; 

export const getAllWorkoutAutocomplete = async () => {
  return await postAutocompleteTemplate(ROUTE_URL);
}; 

export const getAllWorkoutWithoutMedia = async () => {
  return await postWithoutMediaTemplate(ROUTE_URL);
}; 

export const getWorkoutById = async (id) => {
  return await getByIdTemplate(`/${ROUTE_URL}/${id}`);
};

export const newWorkout = async (payload) => {
  return await newTemplate(ROUTE_URL, payload);
};

export const deleteWorkout = async (id) => {
  return await deleteTemplate(`/${ROUTE_URL}/${id}`);
};

export const patchWorkout = async (id, payload) => {
  return await patchTemplate(`/${ROUTE_URL}/${id}`, payload);
};