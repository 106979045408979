/* eslint-disable no-unused-vars */
import { Alert, Box, Snackbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import ItemCardDefault from "pages/admin/components/ItemCardDefault";
import EditModal from "./components/EditModal";
import NewModal from "./components/NewModal";
import SearchAppBar from "components/CODXSearchAppBar/SearchAppBar";
import {
  getAllPost,
  newPost,
  deletePost,
  patchPost,
} from "api/crud/hooks/useAvisos";
import { getAllTargetArea } from "api/crud/hooks/useGruposMusculares";
import { ItemCardAvisos } from "./components/ItemCardAvisos";
import TableAvisos from "./components/TableAvisos";
import HeaderAvisos from "./components/HeaderAvisos";
import SimpleBackdrop from "components/SimpleBackdrop";
import EmptyCard from "pages/admin/components/EmptyCard";

const PAGE_NAME = "Avisos";

const Avisos = () => {
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [openText, setOpenText] = React.useState("");
  const [openErr, setOpenErr] = React.useState(false);
  const [openErrText, setOpenErrText] = React.useState("");
  const [open, setOpen] = useState(true);

  const [busca, setBusca] = useState("");
  const [items, setItems] = useState([]);
  const [gruposMusculares, setGruposMusculares] = useState([]);


  const handleClose = () => setOpenSuccess(false);
  const handleCloseErr = () => setOpenErr(false);

  useEffect(() => {
    GetItens();
  }, []);

  const GetItens = async () => {
    try {
      const { status, message } = await getAllPost();
      console.log('getAllPost', message)
      setOpen(false)
      if (status === 200) {
        setItems(message);
      } else {
        setItems([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const AddNewItem = async (payload) => {
    setOpen(true)
    try {
      const { status, message } = await newPost(payload);
      setRes(status, message);
    } catch (error) {
      console.error(error);
    }
    GetItens();
  };

  const handleDeleteItem = async (id) => {
    setOpen(true)
    try {
      const { status, message } = await deletePost(id);
      setRes(status, message);
    } catch (error) {
      console.error(error);
    }
    GetItens();
  };

  const handlePatchItem = async (id, payload) => {
    setOpen(true)
    try {
      const { status, message } = await patchPost(id, payload);
      setRes(status, message);
    } catch (error) {
      console.error(error);
    }
    GetItens();
  };

  const setRes = (status, message) => {
    if (status === 200) {
      setOpenText(message);
      setOpenSuccess(true);
    } else {
      setOpenErrText(message);
      setOpenErr(true);
    }
  }

  return (
    <>

      <SimpleBackdrop open={open} />
      <HeaderAvisos addFunction={AddNewItem} tittle={PAGE_NAME} NewModal={NewModal} />
      <Box component="main" sx={{ flex: 1, py: 6, px: 4, bgcolor: "#f2f2f2" }}>
        <SearchAppBar
          value={busca}
          onChange={(caracter) => {
            setBusca(caracter);
          }}
        />
        <br />
        {items ? (
          <TableAvisos
            itens={items}
            busca={busca}
            EditModal={EditModal}
            handleDeleteItem={handleDeleteItem}
            handlePatchItem={handlePatchItem}
            gruposMusculares={gruposMusculares}
          />
        ) : (
          <EmptyCard nome="Avisos não encontrados" />
        )}
      </Box>
      <Snackbar open={openSuccess} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          {openText}
        </Alert>
      </Snackbar>
      <Snackbar open={openErr} autoHideDuration={3000} onClose={handleCloseErr}>
        <Alert onClose={handleCloseErr} severity="error" sx={{ width: "100%" }}>
          {openErrText}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Avisos;
