import * as React from "react";
import PropTypes from "prop-types";

import EditIcon from "@mui/icons-material/Edit";
import UploadIcon from "@mui/icons-material/Upload";

import { Typography, Modal, Box, Button, Divider, IconButton, TextField } from "@mui/material";
import MaskedInput from "react-text-mask";
import { CpfValidator } from "utils/cpfValidator";
import { useState } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  maxHeight: '90%',
  overflowY: 'auto',
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "20px",
};
const cpfMask = [/[0-9]/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/,];


export default function EditModal({ item, id, deleteItem, patchItem }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [ErrorMessage, setErrorMessage] = useState();
  const [ErrorPassword, setErrorPassword] = useState();
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const correctCPF = await validateDatas(data);

    if (correctCPF) {

      const payload = {
        name: data.get("name"),
        cpf: removerMascara(data.get("cpf")),
        phoneNumber: data.get("telefone"),
      };

      if (data.get('senha')) {
        if (data.get('senha') === data.get('senha2')) {
          payload.password = data.get('senha')
          setErrorPassword('')
          patchItem(id, payload);
          return handleClose();
        } else {
          setErrorPassword('Confira as Senhas.')
        }
      } else {
        patchItem(id, payload);
        return handleClose();

      }
    } 
    return true
  };

  function removerMascara(cpfMascarado) {
    return cpfMascarado.replace(/\D/g, '');
  }

  const validateDatas = async (data) => {
    var res = await CpfValidator(data.get('cpf'));

    if (res) {

      setErrorMessage()
      return true;

    } else {
      setErrorMessage("CPF inválido")
      return false

    }
  }

  const handleDeleteItem = async () => {
    deleteItem(id);
    return handleClose();
  };

  return (
    <div>
      <IconButton aria-label="delete" size="large" onClick={handleOpen}>
        <EditIcon fontSize="inherit" />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" align="center">
            Editar Treinadores
          </Typography>
          <IconButton
            aria-label="fechar"
            size="large"
            onClick={handleClose}
            style={{ position: "absolute", top: "10px", right: "10px" }}
          >
            X
          </IconButton>
          <Divider />
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }} align="center">

            <Box sx={{ mt: 1, mb: 1 }}>
              <TextField margin="normal" id="name" label="Nome" name="name" required fullWidth defaultValue={item.name} />
            </Box>

            <Box sx={{ mb: 1 }}>
              <MaskedInput mask={cpfMask} label={'CPF'} name="cpf"
                defaultValue={item.cpf}
                guide={false}
                render={(ref, props) => (
                  <TextField autoFocus variant="outlined" required fullWidth inputRef={ref} {...props} helperText={ErrorMessage} error={ErrorMessage ? true : false} />
                )}
              />
            </Box>

            <Box sx={{ mb: 1 }}>
              <TextField margin="normal" id="telefone" label="Telefone" name="telefone" type="number"
                required fullWidth defaultValue={item.phoneNumber} />
            </Box>
            <Divider>Alterar Senha</Divider>
            <Box sx={{ mb: 1 }}>
              <TextField margin="normal" id="senha" label="Senha" name="senha" type="password"
                helperText={ErrorPassword} error={ErrorPassword ? true : false} fullWidth />
            </Box>
            <Box sx={{ mb: 1 }}>
              <TextField margin="normal" id="senha2" label="Repita a Senha" name="senha2" type="password"
                helperText={ErrorPassword} error={ErrorPassword ? true : false} fullWidth />
            </Box>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 2, mb: 2, bgcolor: "primary.main" }}
            >
              Editar
            </Button>
            <Button
              fullWidth
              variant="contained"
              color="error"
              sx={{ mt: 2, mb: 2 }}
              onClick={handleDeleteItem}
            >
              Deletar
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

EditModal.propTypes = {
  name: PropTypes.string.isRequired,
  image: PropTypes.string,
  id: PropTypes.number.isRequired,
  deleteItem: PropTypes.func.isRequired,
  patchItem: PropTypes.func.isRequired,
};
