import React from 'react'
import caixaVazia from './caixa-vazia.png'
import { Grid } from '@mui/material'

const EmptyCard = ({nome}) => {
  return (
    <Grid container align="center">
      <Grid sm={12} xs={12} mb={2}>
      <img src={caixaVazia} style={{width:'250px'}} alt="" />
      </Grid>
      <Grid sm={12} xs={12}>
        {nome}
      </Grid>
       <br />
      
    </Grid>
  )
}

export default EmptyCard
