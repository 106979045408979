// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// Images
//import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import bgImage from "assets/img/acadFundo.png";
import { Link, Navigate } from "react-router-dom";
import AuthContext from "contexts/AuthContext";
import { useContext, useState } from "react";

import logoArena from "../../assets/img/logoArena.png";

function Register() {
  const { verifyLogged } = useContext(AuthContext);
  const [getErr, setErr] = useState("");
  const [getErrMsg, setErrMsg] = useState("");
  const [getUser, setUser] = useState(false);
  const { loginAdm } = useContext(AuthContext);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);

    const payload = {
      login: data.get("login"),
      password: data.get("password"),
    };
    console.log(payload);

    await loginAdm(payload)
      .then(() => {
        setUser(true);
      })
      .catch(() => {
        setErr(true);
        setErrMsg("Dados Incorretos");
      });
  };

  return (
    <>
      <MKBox
        position="absolute"
        top={0}
        left={0}
        zIndex={1}
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <MKBox px={1} width="100%" height="100vh" mx="auto" position="relative" zIndex={2}>
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            <Card>
              <MKBox
                variant="gradient"
                bgColor="dark"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <MKTypography variant="h6" fontWeight="medium" color="white" mt={1}>
                  Vamos começar sua jornada na
                </MKTypography>
                <img src={logoArena} style={{ height: "170px" }} alt="" />
                <MKTypography variant="h6" fontWeight="medium" color="white" mt={1}>
                  Preencha seus dados!
                </MKTypography>
              </MKBox>
              <MKBox pt={4} pb={3} px={3}>
                <MKBox component="form" role="form" onSubmit={handleSubmit} required>
                  <MKBox mb={4}>
                    <MKInput
                      error={getErr}
                      id="login"
                      name="login"
                      type="text"
                      label="Nome"
                      fullWidth
                    />
                  </MKBox>
                  <MKBox mb={4}>
                    <MKInput
                      error={getErr}
                      id="login"
                      name="login"
                      type="text"
                      label="CPF"
                      fullWidth
                    />
                  </MKBox>
                  <MKBox mb={4}>
                    <MKInput
                      error={getErr}
                      id="login"
                      name="login"
                      type="text"
                      label="e-mail"
                      fullWidth
                    />
                  </MKBox>
                  <MKBox mb={4}>
                    <MKInput
                      error={getErr}
                      id="login"
                      name="login"
                      type="text"
                      label="Telefone"
                      fullWidth
                    />
                  </MKBox>
                  <MKBox mb={4}>
                    <MKInput
                      error={getErr}
                      id="login"
                      name="login"
                      type="text"
                      label="impedimentos"
                      fullWidth
                    />
                  </MKBox>
                  <MKBox mb={4}>
                    <MKInput
                      id="password"
                      name="password"
                      type="password"
                      label="Senha"
                      required
                      fullWidth
                      error={getErr}
                    />
                  </MKBox>
                  <MKBox mb={4}>
                    <MKInput
                      id="password"
                      name="password"
                      type="password"
                      label="Repita a senha"
                      required
                      fullWidth
                      error={getErr}
                    />
                  </MKBox>
                  <MKTypography component="h7" variant="h7" color="red">
                    {getErrMsg}
                  </MKTypography>
                  <MKBox mt={4} mb={1}>
                    <MKButton type="submit" variant="gradient" color="dark" fullWidth>
                      Criar Conta
                    </MKButton>
                  </MKBox>
                </MKBox>
              </MKBox>
              <MKTypography sx={{ mb: 4 }} variant="body2" color="text.secondary" align="center">
                {"Copyright © "}
                <Link color="inherit" href="https://www.codx.dev.br/">
                  CodX
                </Link>{" "}
                {new Date().getFullYear()}
                {"."}
              </MKTypography>
            </Card>
          </Grid>
        </Grid>
      </MKBox>
      {getUser && <Navigate to="/login" replace={true} />}
      {verifyLogged() && <Navigate to="/login" replace={true} />}
    </>
  );
}

export default Register;
