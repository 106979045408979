import { deleteTemplate, getTemplate, newTemplate, patchTemplate } from "../reqTemplate";

var ROUTE_URL = 'post';

export const getAllPost = async () => {
  return await getTemplate(ROUTE_URL);
}; 

export const newPost = async (payload) => {
  console.log('newPost', payload)
  return await newTemplate(ROUTE_URL, payload);
};

export const deletePost = async (id) => {
  return await deleteTemplate(`/${ROUTE_URL}/${id}`);
};

export const patchPost = async (id, payload) => {
  return await patchTemplate(`/${ROUTE_URL}/${id}`, payload);
};
