import api_crud from "./api_crud";

export const getTemplate = async (route) => {
  
  const header = { Authorization: `Bearer ${localStorage.getItem("token")}` };
  const parameters = { headers: header };
  const res = await api_crud.get(route, parameters);

  console.log('getTemplate' , route, res)

  if (res.status === 200) {

    if (res.data.status >= 200 && res.data.status <= 209 ) {
      return { status: 200, message: res.data ? res.data.data : [] };
    } else if (res.data.status === 401) {
      localStorage.clear();
      return { status: 400, message: [] };
    }else {
      return { status: 400, message: [] };
    }

  } else {
    return { status: 400, message: [] };
  }

};

export const postAutocompleteTemplate = async (route) => {
  
  const header = { Authorization: `Bearer ${localStorage.getItem("token")}` };
  const parameters = { headers: header };
  const res = await api_crud.post(route+'/autocomplete', null ,parameters);

  console.log('getTemplate' , route, res)

  if (res.status === 200) {

    if (res.data.status >= 200 && res.data.status <= 209 ) {
      return { status: 200, message: res.data ? res.data.data : [] };
    } else if (res.data.status === 401) {
      localStorage.clear();
      return { status: 400, message: [] };
    }else {
      return { status: 400, message: [] };
    }

  } else {
    return { status: 400, message: [] };
  }
};

export const postWithoutMediaTemplate = async (route) => {
  
  const header = { Authorization: `Bearer ${localStorage.getItem("token")}` };
  const parameters = { headers: header };
  const res = await api_crud.post(route+'/withoutMedia', null ,parameters);

  console.log('getTemplate' , route, res)

  if (res.status === 200) {

    if (res.data.status >= 200 && res.data.status <= 209 ) {
      return { status: 200, message: res.data ? res.data.data : [] };
    } else if (res.data.status === 401) {
      localStorage.clear();
      return { status: 400, message: [] };
    }else {
      return { status: 400, message: [] };
    }

  } else {
    return { status: 400, message: [] };
  }
};

export const getByIdTemplate = async (route) => {
  
  const header = { Authorization: `Bearer ${localStorage.getItem("token")}` };
  const parameters = { headers: header };
  const res = await api_crud.get(route, parameters);

  console.log('getByIdTemplate', route, res)

  if (res.status === 200) {

    if (res.data.status >= 200 && res.data.status <= 209 ) {
      return { status: 200, message: res.data ? res.data.data : [] };
    } else if (res.data.status === 401) {
      localStorage.clear();
      return { status: 400, message: [] };
    }else {
      return { status: 400, message: [] };
    }
    
  } else {
    return { status: 400, message: [] };
  }

};

export const newTemplate = async (route, payload) => {

  const header = { Authorization: `Bearer ${localStorage.getItem("token")}` };
  const parameters = { headers: header };
  const res = await api_crud.post(route, payload, parameters);

  console.log('newTemplate', route, res, payload)

  if (res.status === 200) {

    if (res.data.status >= 200 && res.data.status <= 209 ) {
      return { status: 200, message: "Adicionado com sucesso !" };
    } else if (res.data.status === 401) {
      localStorage.clear();
      return { status: 400, message: "Erro ao Adicionar." };
    } else if (res.data.status === 409) {
      return { status: 409, message: "CPF já cadastrado" };
    } else {
      return { status: 400, message: "Erro ao Adicionar." };
    }

  } else {
    return { status: 400, message: "Erro ao Adicionar." };
  }

};

export const deleteTemplate = async (route) => {
  
  const header = { Authorization: `Bearer ${localStorage.getItem("token")}` };
  const parameters = { headers: header };
  const res = await api_crud.delete(route, parameters);

  console.log('getByIdTemplate', route, res)

  if (res.status === 200) {

    if (res.data.status >= 200 && res.data.status <= 209 ) {
      return { status: 200, message: "Excluido com sucesso !" };
    } else if (res.data.status === 401) {
      localStorage.clear();
      return { status: 400, message: "Erro ao excluir." };
    }else {
      return { status: 400, message: "Erro ao excluir." };
    }

  } else {
    return { status: 400, message: "Erro ao excluir." };
  }
};

export const patchTemplate = async (route, payload) => {
  
  const header = { Authorization: `Bearer ${localStorage.getItem("token")}` };
  const parameters = { headers: header };
  const res = await api_crud.patch(route, payload, parameters);
  
  console.log('getByIdTemplate', route, res, payload)

  if (res.status === 200) {

    if (res.data.status >= 200 && res.data.status <= 209 ) {
      return { status: 200, message: "Editado com sucesso!" };
    } else if (res.data.status === 401) {
      localStorage.clear();
      return { status: 400, message: "Erro ao editar." };
    }else {
      return { status: 400, message: "Erro ao editar." };
    }

  } else {
    return { status: 400, message: "Erro ao editar." };
  }
};
