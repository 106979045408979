import { encryptCryptoJs } from "utils/crypto";
import api_login from "../api_login";

export async function postLogin(payload) {

    const res = await api_login.post('auth/login', payload);

    if (res.data.status === 200) {
      
      const loginData = res.data.data;
      loginData.role = "planId" in loginData ? 'aluno' : 'professor';

      const resLoginStringfied = JSON.stringify(res.data.data);
      const encryptMk = await encryptCryptoJs(resLoginStringfied);
      localStorage.setItem("encodedData", encryptMk);
      
      const resLoginPayload = JSON.stringify(payload);
      const encryptLogin = await encryptCryptoJs(resLoginPayload);
      localStorage.setItem("encodedLogin", encryptLogin);
      
      localStorage.setItem("token", res.data.data.token);

      return { status: 200, message: res.data.data };
    } else {
      return { status: 400, message: "Erro ao Entrar!" };
    }

}
