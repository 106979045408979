/* eslint-disable no-unused-vars */
import React from "react";
import { Box, Card, CardContent, CardMedia, Typography } from "@mui/material";
//import EditModal from "./EditModal";

const ItemCardTreinadores = ({ name, value, children }) => {

  return (
    <Card sx={{ mb: 2 }}>
      <CardContent style={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ ml: 2 }}>
          <Typography variant="h5">{name ? name : "Item não Encontrado"}</Typography>
        </Box>

        <div style={{ marginLeft: "auto" }}>{children}</div>
      </CardContent>
    </Card>
  );
};

export { ItemCardTreinadores };
