import * as React from 'react';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';

import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';

import MoreVertIcon from '@mui/icons-material/MoreVert';

import logoArena from "../../../../assets/img/logoArena.png";

export default function AvisoCard({ post }) {

  const formatarData = (dataString) => {
    const data = new Date(dataString);

    const dia = data.getUTCDate();
    const mes = data.getUTCMonth() + 1;
    const ano = data.getUTCFullYear();

    const horas = data.getUTCHours();
    const minutos = data.getUTCMinutes();

    const diaFormatado = dia < 10 ? `0${dia}` : dia;
    const mesFormatado = mes < 10 ? `0${mes}` : mes;
    const horasFormatadas = horas < 10 ? `0${horas}` : horas;
    const minutosFormatados = minutos < 10 ? `0${minutos}` : minutos;

    return `${diaFormatado}/${mesFormatado}/${ano} ${horasFormatadas}:${minutosFormatados}`;
  };

  return (
    <Card sx={{ maxWidth: 345, mb: 3 }} elevation='4' align="center">
      <CardHeader
        title={post.title}
        subheader={formatarData(post.createdAt)}

      />
      {post.imageUrl && (post.imageUrl.startsWith("data:image")) ? (
        <img alt='gif' src={post.imageUrl} style={{ width: "300px", height: "300px", borderRadius: "10px" }}></img>
      ) : (
        <video playsinline src={post.imageUrl} style={{ width: "300px", height: "300px", borderRadius: "10px" }}
          autoPlay={true} loop={true} muted={true}></video>
      )}
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          {post.content}
        </Typography>
      </CardContent>
    </Card>
  );
}