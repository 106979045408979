import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { getAllPost } from "api/crud/hooks/useAvisos";
import AvisoCard from "./AvisoCard";
import SimpleBackdrop from "components/SimpleBackdrop";
import EmptyCard from "./EmptyCard";


const AvisoAluno = () => {

  const [items, setItems] = useState([]);
  const [open, setOpen] = useState(true);

  useEffect(() => {
    GetItens();
  }, []);

  const GetItens = async () => {
    try {
      const { status, message } = await getAllPost();
      console.log('getAllPost', message)
      setOpen(false)
      if (status === 200) {
        setItems(message);
      } else {
        setItems([]);
      }
    } catch (error) {
      setItems([]);
      console.error(error);
    }
  };

  return (
    <>
      <SimpleBackdrop open={open} />
      <Box component="main"
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          py: 6
        }}
      >
        <Typography sx={{ fontSize: '22px', mb: 4 }} align="center">
          Avisos
        </Typography>

        {Array.isArray(items) && items.length > 0 ? (
          items.map((post) => (
            <AvisoCard key={post.id} post={post} />
          ))
        ) : (
          <EmptyCard />
        )}

      </Box>
    </>
  );
};

export default AvisoAluno;
