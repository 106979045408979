import * as React from "react";
import { Divider, Typography, Modal, Box, Button, TextField, FormControl, MenuItem, InputLabel, Select, Grid, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import UploadIcon from "@mui/icons-material/Upload";
import { useState } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  maxHeight: '90%',
  overflowY: 'auto',
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "20px",
};

export default function NewModal({ gruposMusculares, addFunction }) {

  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const lightColor = "rgba(255, 255, 255, 0.7)";
  const [base64Image, setBase64Image] = React.useState("");
  const [base64Video, setBase64Video] = React.useState("");
  const [grupoMuscular, setGrupoMuscular] = useState([]);

  const handleOpen = () => {
    setBase64Image("");
    setBase64Video("");
    setGrupoMuscular([])
    setOpen(true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const payload = {
      description: data.get("name"),
      execution: data.get("execucao"),
      targetAreas: grupoMuscular,
      imageUrl: base64Image,
      videoUrl: base64Video,
    };

    addFunction(payload);
    return handleClose();
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result;
        setBase64Image(base64String);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleVideoUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result;
        setBase64Video(base64String);
      };

      reader.readAsDataURL(file);
    }
  };

  const changeGrupo = (event) => {
    const selectedValues = Array.isArray(event.target.value) ? event.target.value.filter(value => value !== undefined) : event.target.value;
    console.log(selectedValues)
    setGrupoMuscular(selectedValues);
  };

  return (
    <div>
      <Button
        variant="outlined"
        color="inherit"
        sx={{ borderColor: lightColor }}
        startIcon={<AddIcon />}
        onClick={handleOpen}
        spacing={2}
      >
        Adicionar
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" align="center">
            Adicionar Exercicio
          </Typography>
          <IconButton
            aria-label="fechar"
            size="large"
            onClick={handleClose}
            style={{ position: "absolute", top: "10px", right: "10px" }}
          >
            X
          </IconButton>
          <Divider />
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }} align="center">
            <Box component="div" sx={{ mt: 1, mb: 1 }}>
              <TextField
                margin="normal"
                id="name"
                label="Nome"
                name="name"
                variant="standard"
                autoComplete="name"
                required
                fullWidth
                autoFocus
              />
            </Box>
            <Box component="div" sx={{ mt: 1, mb: 1 }}>
              <TextField
                margin="normal"
                id="execucao"
                label="Execução"
                name="execucao"
                variant="standard"
                autoComplete="execucao"
                required
                fullWidth
                helperText="Exemplo: 4 X de 15"
              />
            </Box>
            <Box component="div" sx={{ mt: 1, mb: 2 }}>
              <FormControl fullWidth margin="normal">
                <InputLabel>Grupo muscular</InputLabel>
                <Select
                  onChange={changeGrupo}
                  value={grupoMuscular}
                  label='Grupo muscular'
                  required
                  multiple
                >
                  {gruposMusculares && gruposMusculares.map(grupo => (
                    <MenuItem value={grupo.id} key={grupo.id}>
                      {grupo.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box component="div" sx={{ mt: 1, mb: 2 }}>
              {base64Image ? (
                <Button
                  startIcon={<UploadIcon />}
                  variant="outlined"
                  component="label"
                  color="success"
                  fullWidth
                  sx={{ mt: 1 }}
                >

                  Alterar Imagem
                  <input type="file" onChange={handleImageUpload} hidden />
                </Button>
              ) : (
                <Button
                  startIcon={<UploadIcon />}
                  variant="outlined"
                  component="label"
                  color="inherit"
                  fullWidth
                  sx={{ mt: 1 }}
                >

                  Escolher Imagem
                  <input type="file" onChange={handleImageUpload} hidden />
                </Button>
              )}
            </Box>

            <Box component="div" sx={{ mt: 1, mb: 2 }}>
              {base64Video ? (
                <Button
                  startIcon={<UploadIcon />}
                  variant="outlined"
                  component="label"
                  color="success"
                  fullWidth
                  sx={{ mt: 1, mb: 4 }}
                >
                  Escolher Video
                  <input type="file" onChange={handleVideoUpload} hidden />
                </Button>
              ) : (
                <Button
                  startIcon={<UploadIcon />}
                  variant="outlined"
                  component="label"
                  color="inherit"
                  fullWidth
                  sx={{ mt: 1, mb: 4 }}
                >
                  Escolher Video
                  <input type="file" onChange={handleVideoUpload} hidden />
                </Button>
              )}
            </Box>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 2, mb: 2, bgcolor: "primary.main" }}
            >
              Adicionar
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}