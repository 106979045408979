/* eslint-disable no-unused-vars */
import { Alert, Box, Snackbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import HeaderPageDefault from "pages/admin/components/HeaderPageDefault";
import ItemCardDefault from "pages/admin/components/ItemCardDefault";
import EditModal from "./components/EditModal";
import NewModal from "./components/NewModal";
import SearchAppBar from "components/CODXSearchAppBar/SearchAppBar";
import CustomPaginationActionsTable from "components/CODXTablePagination/TablePaginationActions";

import HeaderPlanosAulas from "./components/HeaderPlanosAulas";
import { getAllExercise } from "api/crud/hooks/useExercicios";
import TableTreinos from "./components/TableTreinos";

import {
  getAllTrainingPlan,
  newTrainingPlan,
  deleteTrainingPlan,
  patchTrainingPlan,
} from "api/crud/hooks/usePlanosAulas";
import { getAllWorkout } from "api/crud/hooks/useTreinos";

import SimpleBackdrop from "components/SimpleBackdrop";
import EmptyCard from "pages/admin/components/EmptyCard";
import { getAllWorkoutAutocomplete } from "api/crud/hooks/useTreinos";

const PAGE_NAME = "Planos de Aulas";

const PlanosAulas = () => {
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openText, setOpenText] = useState("");
  const [openErr, setOpenErr] = useState(false);
  const [openErrText, setOpenErrText] = useState("");
  const [busca, setBusca] = useState("");
  const [items, setItems] = useState([]);
  
  const [open, setOpen] = useState(true);
  
  const handleClose = () => setOpenSuccess(false);
  const handleCloseErr = () => setOpenErr(false);
  
  const [treinos, setTreinos] = useState([]);
  const [loadingTreinos, setLoadingTreinos] = useState(true);

  useEffect(() => {
    GetItens();
    GetTreinos();
  }, []);

  const GetTreinos = async () => {
    try {
      const { status, message } = await getAllWorkoutAutocomplete();
      if (status === 200) {
        setTreinos(message);
        setLoadingTreinos(false)
      } else {
        setTreinos([]);
        setLoadingTreinos(false)
      }
    } catch (error) {
      setTreinos([]);
      setLoadingTreinos(false)
    }
  };

  const GetItens = async () => {
    setOpen(false)
    try {
      const { status, message } = await getAllTrainingPlan();
      if (status === 200) {
        console.log('GetItens Treinos: ', message)
        setItems(message);
      } else {
        setItems([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const AddNewItem = async (payload) => {
    setOpen(true)
    try {
      const { status, message } = await newTrainingPlan(payload);
      setRes(status, message);
    } catch (error) {
      console.error(error);
    }
    GetItens();
  };

  const handleDeleteItem = async (id) => {
    setOpen(true)
    try {
      const { status, message } = await deleteTrainingPlan(id);
      setRes(status, message);
    } catch (error) {
      console.error(error);
    }
    GetItens();
  };

  const handlePatchItem = async (id, payload) => {
    setOpen(true)
    try {
      const { status, message } = await patchTrainingPlan(id, payload);
      setRes(status, message);
    } catch (error) {
      console.error(error);
    }
    GetItens();
  };

  const setRes = (status, message) => {
    if (status === 200) {
      setOpenText(message);
      setOpenSuccess(true);
    } else {
      setOpenErrText(message);
      setOpenErr(true);
    }
  }

  return (
    <>
      <SimpleBackdrop open={open} />
      <HeaderPlanosAulas addFunction={AddNewItem} tittle={PAGE_NAME} NewModal={NewModal} treinos={treinos} loadingTreinos={loadingTreinos}/>
      <Box component="main" sx={{ flex: 1, py: 6, px: 4, bgcolor: "#f2f2f2" }}>
        <SearchAppBar
          value={busca}
          onChange={(caracter) => {
            setBusca(caracter);
          }}
        />
        <br />
        {items ? (
          <TableTreinos
            itens={items}
            busca={busca}
            EditModal={EditModal}
            handleDeleteItem={handleDeleteItem}
            handlePatchItem={handlePatchItem}
            treinos={treinos}
            loadingTreinos={loadingTreinos}
          />
        ) : (

          <EmptyCard nome="Planos de aula não encontrados" />
        )}
      </Box>
      <Snackbar open={openSuccess} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          {openText}
        </Alert>
      </Snackbar>
      <Snackbar open={openErr} autoHideDuration={3000} onClose={handleCloseErr}>
        <Alert onClose={handleCloseErr} severity="error" sx={{ width: "100%" }}>
          {openErrText}
        </Alert>
      </Snackbar>
    </>
  );
};

export default PlanosAulas;
