//Router
import { createBrowserRouter, Navigate } from "react-router-dom";

// Layout;
import AdminBase from "pages/admin/AdminBase";

// Private
import Painel from "pages/admin/pages/Painel/Painel";
import GruposMusculares from "pages/admin/pages/GruposMusculares/GruposMusculares";
import Login from "pages/Login";
import Register from "pages/Register";
import Exercicios from "pages/admin/pages/Exercicios/Exercicios";
import Treinos from "pages/admin/pages/Treinos/Treinos";
import AlunoBase from "pages/aluno/AlunoBase";

import PrivateRoute from "./PrivateRoute";
import Treinadores from "pages/admin/pages/Treinadores/Treinadores";
import Planos from "pages/admin/pages/Planos/Planos";
import Alunos from "pages/admin/pages/Alunos/Alunos";
import AvisoAluno from "pages/aluno/pages/AvisoAluno/AvisoAluno";
import TreinoAluno from "pages/aluno/pages/TreinoAluno/TreinoAluno";
import PerfilAluno from "pages/aluno/pages/PerfilAluno/PerfilAluno";
import PlanosAulas from "pages/admin/pages/PlanosAulas/PlanosAulas";
import Exercise from "pages/aluno/pages/TreinoAluno/Exercise";
import Avisos from "pages/admin/pages/Avisos/Avisos";

export const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
    children: [],
  },
  {
    path: "/register",
    element: <Register />,
    children: [],
  },
  {
    path: "*",
    element: <Navigate to="/login" />,
    children: [],
  },
  {
    path: "/private",
    element: <PrivateRoute allowedRole={"professor"} />,
    children: [
      {
        path: "professor",
        element: <AdminBase size="md" />,
        children: [
          {
            path: "painel",
            element: <Painel />,
            children: [],
          },
          {
            path: "gruposMusculares",
            element: <GruposMusculares />,
            children: [],
          },
          {
            path: "exercicios",
            element: <Exercicios />,
            children: [],
          },
          {
            path: "treinos",
            element: <Treinos />,
            children: [],
          },
          {
            path: "treinadores",
            element: <Treinadores />,
            children: [],
          },
          {
            path: "alunos",
            element: <Alunos />,
            children: [],
          },
          {
            path: "planos",
            element: <Planos />,
            children: [],
          },
          {
            path: "planosAula",
            element: <PlanosAulas />,
            children: [],
          },
          {
            path: "posts",
            element: <Avisos />,
            children: [],
          },
        ],
      },
      {
        path: "*",
        element: <Navigate to="/login" />,
        children: [],
      },
    ],
  },
  {
    path: "/private",
    element: <PrivateRoute allowedRole={"aluno"} />,
    children: [
      {
        path: "aluno",
        element: <AlunoBase size="md" />,
        children: [
          {
            path: "aviso",
            element: <AvisoAluno />,
            children: [],
          },
          {
            path: "treino",
            element: <TreinoAluno />,
            children: [],
          },
          {
            path: "perfil",
            element: <PerfilAluno />,
            children: [],
          },
          {
            path: 'exercise/:id',
            element: <Exercise />,
            children: []
          },
        ],
      },
      {
        path: "*",
        element: <Navigate to="/login" />,
        children: [],
      },
    ],
  },
]);
