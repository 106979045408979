// React
import * as React from 'react';
import { useEffect } from "react";
import { useState } from 'react';

// MUI
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

// Router
import { useNavigate, useParams } from 'react-router-dom';

//Components
import SimpleBackdrop from 'components/SimpleBackdrop';
import ExerciseCard from './ExerciseCard';

// Utils
import { getWorkoutById } from 'api/crud/hooks/useTreinos';
import { getExerciseById } from 'api/crud/hooks/useExercicios';

const Exercise = () => {

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getExerciseData()
  }, [])

  const [exercisesData, setExercisesData] = useState([]);
  const [workoutData, setWorkoutData] = useState([]);
  const [open, setOpen] = useState(true);
  const [expanded, setExpanded] = React.useState(false);
  const [base64Video, setBase64Video] = useState("");
  const [oldExercise, setOldExercise] = useState();

  const handleChange = (exerciseId) => (event, isExpanded) => {
    console.log('exerciseId: ', oldExercise, exerciseId)
    if (oldExercise != exerciseId) {
      setOldExercise(exerciseId)
      setOpen(true)
      setBase64Video('');
      GetSingleExercise(exerciseId)
    }
    setExpanded(isExpanded ? exerciseId : false);
  };

  const GetSingleExercise = async (id) => {
    try {
      const { status, message } = await getExerciseById(id);
      console.log('GetItens', message)
      setOpen(false)
      if (status === 200) {
        setBase64Video(message.videoUrl);
      } else {
        setBase64Video();
      }
    } catch (error) {
      setBase64Video();
    }
  };

  const getExerciseData = async () => {

    const data = await getWorkoutById(id);
    setWorkoutData(data.message)
    setExercisesData(data.message.exercises)
    setOpen(false)

  }

  return (
    <>
      <SimpleBackdrop open={open} />
      <Box component="main" sx={{ flex: 1, py: 6, px: 4 }}>

        {(exercisesData) && (
          <Typography sx={{ fontSize: '18px', mb: 2 }} align="center">
            {workoutData.description}
          </Typography>

        )}

        {(exercisesData) ?
          (
            exercisesData.map((exercise) => (
              <ExerciseCard exercise={exercise} expanded={expanded} handleChange={handleChange} base64Video={base64Video} />
            ))
          ) : ''
        }

        {(!open) && (
          <Grid item xs={12} sm={12}>
            <Stack sx={{ boxShadow: 2, mb: 2, marginX: 1, mt: 4, borderRadius: '15px' }} >
              <Button variant="contained" size="md" startIcon={<ArrowBackIosIcon />} onClick={() => navigate(-1)} spacing={2}>
                Voltar
              </Button>
            </Stack>
          </Grid>
        )}

      </Box>
    </>
  );
};

export default Exercise;

