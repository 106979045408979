import * as React from "react";
import {
  Drawer,
  List,
  Box,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@mui/material";

import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import SchoolIcon from "@mui/icons-material/School";
import PeopleIcon from "@mui/icons-material/People";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import BoyIcon from "@mui/icons-material/Boy";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import ArticleIcon from '@mui/icons-material/Article';
import AssignmentIcon from '@mui/icons-material/Assignment';

import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/img/logoArena.png";

const categories = [
  {
    children: [
      { id: "Alunos", icon: <PeopleIcon />, path: "/private/professor/alunos" },
      { id: "Treinadores", icon: <SchoolIcon />, path: "/private/professor/treinadores" },
    ],
  },
  {
    children: [
      { id: "Planos de Aulas", icon: <ArticleIcon />, path: "/private/professor/planosAula" },
      { id: "Treinos", icon: <DirectionsRunIcon />, path: "/private/professor/treinos" },
      { id: "Exercícios", icon: <FitnessCenterIcon />, path: "/private/professor/exercicios" },
      { id: "Grupos Musculares", icon: <BoyIcon />, path: "/private/professor/gruposMusculares" },
    ],
  },
  {
    children: [
      { id: "Planos", icon: <CreditCardIcon />, path: "/private/professor/planos" },
      { id: "Avisos", icon: <AssignmentIcon />, path: "/private/professor/posts" },
    ],
  },
];

const item = {
  py: "6px",
  px: 3,
  color: "rgba(0, 0, 0, 0.9)",
  "&:hover, &:focus": {
    bgcolor: "rgba(0, 0, 0, 0.08)",
  },
};

export default function Navigator(props) {
  const location = useLocation();
  const { ...other } = props;
  const navigate = useNavigate();

  const logout = () => {
    localStorage.clear();
    return navigate("/login");
  };

  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <Box
          sx={{
            color: "#ff6500",
            position: "relative",
            bgcolor: "rgb(0, 0, 0, 0.92)",
            py: 2,
            display: "flex", // Tornando o conteúdo flexível
            alignItems: "center", // Alinhamento vertical
            justifyContent: "center", // Alinhamento horizontal
          }}
        >
          <img
            src={logo}
            alt="Logo da Arena Sunset"
            style={{ maxWidth: "100%", maxHeight: "77px", display: "block" }}
          />
        </Box>
        <Divider />
        {categories.map(({ id, children }) => (
          <Box key={id} sx={{ bgcolor: "#f2f2f2", pt: 1 }}>
            {children.map(({ id: childId, icon, path }) => (
              <ListItem disablePadding key={childId}>
                <ListItemButton selected={location.pathname === path} sx={item} as={Link} to={path}>
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText>{childId}</ListItemText>
                </ListItemButton>
              </ListItem>
            ))}
             <Divider sx={{ mt: 1, mb: 1 }} />
          </Box>
        ))}

        <ListItem key="pedidos">
          <ListItemButton sx={item} onClick={logout}>
            <ListItemIcon>
              <ArrowLeftIcon />
            </ListItemIcon>
            <ListItemText>Sair</ListItemText>
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer>
  );
}
