import { deleteTemplate, getTemplate, newTemplate, patchTemplate } from "../reqTemplate";

var ROUTE_URL = 'targetArea';

export const getAllTargetArea = async () => {
  return await getTemplate(ROUTE_URL);
}; 

export const newTargetArea = async (payload) => {
  return await newTemplate(ROUTE_URL, payload);
};

export const deleteTargetArea = async (id) => {
  return await deleteTemplate(`/${ROUTE_URL}/${id}`);
};

export const patchTargetArea = async (id, payload) => {
  return await patchTemplate(`/${ROUTE_URL}/${id}`, payload);
};
