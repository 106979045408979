/* eslint-disable no-unused-vars */
import React from "react";
import { Box, Card, CardContent, CardMedia, Typography } from "@mui/material";
//import EditModal from "./EditModal";

const ItemCardPlanos = ({ description, value, children }) => {

  return (
    <Card sx={{ mb: 2 }}>
      <CardContent style={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ ml: 2 }}>
          <Typography variant="h5">{description ? description : "Planos não Encontrados"}</Typography>
          {(value) && (
            <Typography variant="body2">R$ {value}</Typography>
          )}
        </Box>

        <div style={{ marginLeft: "auto" }}>{children}</div>
      </CardContent>
    </Card>
  );
};

export { ItemCardPlanos };
