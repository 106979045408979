import * as React from "react";
import { Divider, Typography, Modal, Box, Button, TextField, FormControl, MenuItem, InputLabel, Select, IconButton, Autocomplete } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import UploadIcon from "@mui/icons-material/Upload";
import { useState } from "react";
import Example from './Example'
import CircularProgress from '@mui/material/CircularProgress';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "20px",
};


export default function NewModal({ treinos, addFunction, loadingTreinos }) {

  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const lightColor = "rgba(255, 255, 255, 0.7)";
  const [treinoIds, setTreinoIds] = useState([]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleChange = (event, value) => {
    const selectedIds = value.map((treino) => treino.id);
    setTreinoIds(selectedIds);
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const payload = {
      description: data.get("name"),
      workouts: treinoIds,
    };

    addFunction(payload);
    return handleClose();
  };

  return (
    <div>

      <Button variant="outlined" color="inherit" sx={{ borderColor: lightColor }} startIcon={<AddIcon />} onClick={handleOpen} spacing={2}>
        Adicionar
      </Button>

      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>

          <Typography id="modal-modal-title" variant="h6" component="h2" align="center">
            Adicionar Plano de Aula
          </Typography>
          <IconButton
            aria-label="fechar"
            size="large"
            onClick={handleClose}
            style={{ position: "absolute", top: "10px", right: "10px" }}
          >
            X
          </IconButton>

          <Divider />

          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }} align="center">

            <Box component="div" sx={{ mt: 1, mb: 4 }}>
              <TextField
                margin="normal"
                id="name"
                label="Nome"
                name="name"
                variant="standard"
                autoComplete="name"
                required
                fullWidth
                autoFocus
              />
            </Box>

            <Autocomplete
              multiple
              getOptionLabel={(option) => option.description}
              limitTags={2}
              options={treinos}
              loading={loadingTreinos}
              disabled={loadingTreinos}
              defaultValue={[]}
              onChange={handleChange}
              size='small'
              noOptionsText={'Sem opções'}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Treinos"
                  placeholder="+ Treino" 
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loadingTreinos && <CircularProgress color="inherit" size={20} />}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 2, mb: 2, bgcolor: "primary.main" }}
            >
              Adicionar
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}