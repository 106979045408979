import * as React from "react";
import PropTypes from "prop-types";

import EditIcon from "@mui/icons-material/Edit";
import UploadIcon from "@mui/icons-material/Upload";

import { Typography, Modal, Box, Button, Divider, IconButton, TextField, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useState } from "react";
import { getExerciseById } from "api/crud/hooks/useExercicios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  maxHeight: '90%',
  overflowY: 'auto',
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "20px",
};

export default function EditModal({ item, id, deleteItem, patchItem, gruposMusculares }) {
  console.log('item', item)
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [base64Image, setBase64Image] = useState("");
  const [grupoMuscular, setGrupoMuscular] = useState([]);
  const [base64Video, setBase64Video] = React.useState("");

  React.useEffect(() => {
    const areas = item.targetAreas?.map(grupo => grupo.id) || [];
    console.log('areas', areas)
    GetExercise();
    setGrupoMuscular(areas)
    setBase64Image(item.imageUrl);
    //setBase64Video(item.videoUrl);
  }, []);
  
  const GetExercise = async () => {
    try {
      const { status, message } = await getExerciseById(item.id);
      console.log('GetItens', message)
      setOpen(false)
      if (status === 200) {
        setBase64Video(message.videoUrl);
      } else {
        setBase64Video();
      }
    } catch (error) {
      setBase64Video();

    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);


    const payload = {
      description: data.get("name"),
      targetAreas: grupoMuscular,
      execution: data.get("execucao"),
      imageUrl: base64Image,
      videoUrl: base64Video,
    };

    console.log('payload', payload)

    patchItem(id, payload);

    return handleClose();
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result;
        setBase64Image(base64String);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleVideoUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result;
        setBase64Video(base64String);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleDeleteItem = async () => {
    deleteItem(id);
    return handleClose();
  };

  const changeGrupo = (event) => {
    const selectedValues = Array.isArray(event.target.value) ? event.target.value.filter(value => value !== undefined) : event.target.value;
    console.log(selectedValues)
    setGrupoMuscular(selectedValues);
  };

  return (
    <div>
      <IconButton aria-label="delete" size="large" onClick={handleOpen}>
        <EditIcon fontSize="inherit" />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" align="center">
            Editar Exercicio
          </Typography>
          <IconButton
            aria-label="fechar"
            size="large"
            onClick={handleClose}
            style={{ position: "absolute", top: "10px", right: "10px" }}
          >
            X
          </IconButton>
          <Divider />
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }} align="center">
            <Box component="div" sx={{ mt: 1, mb: 1 }}>
              <TextField
                margin="normal"
                id="name"
                label="Nome"
                name="name"
                variant="standard"
                autoComplete="name"
                defaultValue={item.description}
                required
                fullWidth
                autoFocus
              />
            </Box>
            <Box component="div" sx={{ mt: 1, mb: 1 }}>
              <TextField
                margin="normal"
                id="execucao"
                label="Execução"
                name="execucao"
                variant="standard"
                autoComplete="execucao"
                defaultValue={item.execution}
                required
                fullWidth
                helperText="Exemplo: 4 X de 15"
              />
            </Box>
            <FormControl fullWidth margin="normal">
              <InputLabel>Grupo muscular</InputLabel>
              <Select
                onChange={changeGrupo}
                value={grupoMuscular}
                label='Grupo muscular'
                required
                multiple
              >
                {gruposMusculares && gruposMusculares.map(grupo => (
                  <MenuItem value={grupo.id} key={grupo.id}>
                    {grupo.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Button
              startIcon={<UploadIcon />}
              variant="outlined"
              component="label"
              color="inherit"
              fullWidth
              sx={{ mt: 1, mb: 4 }}
            >
              Editar Imagem
              <input type="file" onChange={handleImageUpload} hidden />
            </Button>

            <Box align="center">
              {base64Image && (
                <img src={base64Image} alt="Uploaded" style={{ maxWidth: "200px", mb: 3 }} />
              )}
              <hr />
              <Button
                startIcon={<UploadIcon />}
                variant="outlined"
                component="label"
                color="inherit"
                fullWidth
                sx={{ mt: 1, mb: 4 }}
              >
                Editar Video
                <input type="file" onChange={handleVideoUpload} hidden />
              </Button>

              {(base64Video.startsWith("data:image")) ? (
                <img alt='gif' src={base64Video} style={{ maxWidth: "300px", mb: 3 }}></img>
              ) : (
                <video playsinline src={base64Video} style={{ maxWidth: "300px", mb: 3 }}
                  autoPlay={true} loop={true} muted={true}></video>
              )}

            </Box>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 2, mb: 2, bgcolor: "primary.main" }}
            >
              Salvar
            </Button>
            <Button
              fullWidth
              variant="contained"
              color="error"
              sx={{ mt: 2, mb: 2 }}
              onClick={handleDeleteItem}
            >
              Deletar
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

EditModal.propTypes = {
  name: PropTypes.string.isRequired,
  image: PropTypes.string,
  id: PropTypes.number.isRequired,
  deleteItem: PropTypes.func.isRequired,
  patchItem: PropTypes.func.isRequired,
};
