import * as React from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import logoArena from "../../assets/img/logoArena.png";

import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';

import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Person2Icon from '@mui/icons-material/Person2';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import AssignmentIcon from '@mui/icons-material/Assignment';

import { useState } from "react";
import { Box, Typography } from "@mui/material";

export default function AlunoBase() {

  const navigate = useNavigate();
  const [value, setValue] = useState('treino');

  const handleChange = (event, newValue,) => {

    setValue(newValue);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    switch (newValue) {
      case 'aviso':
        return navigate("/private/aluno/aviso");
      case 'treino':
        return navigate("/private/aluno/treino");
      case 'perfil':
        return navigate("/private/aluno/perfil");
      default:
        return navigate("/private/aluno/treino");
    }

  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
        <Box sx={{ position: 'sticky', top: 0, zIndex: 1000 }}>
          <Paper sx={{ bgcolor: "#1d1d1d", borderRadius: 0, color: '#ffffff' }} elevation={3}>
            <Typography id="modal-modal-title" sx={{ fontSize: '18px' }} align="center">
              ARENA FITNESS ACADEMIA
            </Typography>
          </Paper>
        </Box>
        <CssBaseline />
        <Box sx={{ flex: 1, overflowY: 'auto', minHeight: 'calc(100vh - 96px)' }}>
          <Outlet />
        </Box>
        <Box sx={{ position: 'sticky', bottom: 0, zIndex: 1000 }}>
          <Paper elevation={3}>
            <BottomNavigation showLabels value={value} onChange={handleChange}>
              <BottomNavigationAction value='aviso' label="Avisos" icon={<AssignmentIcon />} />
              <BottomNavigationAction value='treino' label="Treinos" icon={<FitnessCenterIcon />} to="/private/aluno/treino" />
              <BottomNavigationAction value='perfil' label="Perfil" icon={<Person2Icon />} to="/private/aluno/perfil" />
            </BottomNavigation>
          </Paper>
        </Box>
      </Box>
    </ThemeProvider>
  );
  
  
}

let theme = createTheme({
  palette: {
    primary: {
      light: "#63ccff",
      main: "rgb(0, 0, 0, 0.92)",
      dark: "#006db3",
    },
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: "#f2f2f2",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
        contained: {
          boxShadow: "none",
          "&:active": {
            boxShadow: "none",
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginLeft: theme.spacing(1),
        },
        indicator: {
          height: 3,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          backgroundColor: theme.palette.common.white,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          margin: "0 16px",
          minWidth: 0,
          padding: 0,
          [theme.breakpoints.up("md")]: {
            padding: 0,
            minWidth: 0,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1),
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 4,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: "rgb(255,255,255,0.15)",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: "#ff6500",
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: 14,
          fontWeight: theme.typography.fontWeightMedium,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: "inherit",
          minWidth: "auto",
          marginRight: theme.spacing(2),
          "& svg": {
            fontSize: 20,
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 32,
          height: 32,
        },
      },
    },
  },
};


