/* eslint-disable no-unused-vars */
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// Images
//import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import bgImage from "assets/img/acadFundo.png";
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "contexts/AuthContext";
import { useContext, useEffect, useState } from "react";

//import logoArena from "../../assets/img/logoArena.png";
import logoArena from "../../assets/img/arena-laranja.png";
import { decryptCryptoJs } from "utils/crypto";

function Login() {
  const [getErr, setErr] = useState("");
  const [getErrMsg, setErrMsg] = useState("");

  const { login } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(()=>{
    verifyLogged()
  },[])

  const verifyLogged =  async () => {

    var decodedData = null;

    if (localStorage.getItem('encodedData')){
      decodedData = await decryptCryptoJs(localStorage.getItem('encodedData'))
    } 

    const dataJson = decodedData ? JSON.parse(decodedData) : null;

    if (dataJson?.name && dataJson?.id && localStorage.getItem('token')){
      navigates(dataJson.role)
    }

  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);

    const payload = {
      cpf: removerMascara(data.get("login")),
      password: data.get("password"),
    };

    const res = await login(payload);

    if (res.status == 200) {

      const isStudent = ("id_plan" in data);
      const role = isStudent ? 'aluno' : 'professor';

      navigates(role)

    } else {
      setErr(true);
      setErrMsg("Dados Incorretos");
    }
  };

  function removerMascara(cpfMascarado) {
    return cpfMascarado.replace(/\D/g, '');
  }

  const navigates = async (role) => {
    if ( role == 'aluno') {
      console.log('ENTROU IR PARA PAINEL ALUNO')
      return navigate("/private/aluno/treino");
    } else {
      console.log('ENTROU IR PARA PAINEL professor')
      return navigate("/private/professor/alunos");
    }
  }
  return (
    <>
      <MKBox
        position="absolute"
        top={0}
        left={0}
        zIndex={1}
        width="100vw"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <MKBox px={1} width="100vw" height="100vh" mx="auto" position="relative" zIndex={2}>
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            <Card>
              <MKBox
                variant="gradient"
                sx={{bgcolor:'#F37900'}}
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <MKTypography variant="h5" fontWeight="medium" color="white" mt={1}>
                  Bem Vindo a
                </MKTypography>
                <img src={logoArena} style={{ maxHeight: "80%", maxWidth: "80%" }} alt="" />

                <MKTypography variant="h6" fontWeight="medium" color="white" mt={1}>
                  Por favor digite seu usuário e senha!
                </MKTypography>
              </MKBox>
              <MKBox pt={4} pb={3} px={3}>
                <MKBox component="form" role="form" onSubmit={handleSubmit} required>
                  <MKBox mb={4}>
                    <MKInput
                      error={getErr}
                      id="login"
                      name="login"
                      type="text"
                      label="CPF"
                      fullWidth
                    />
                  </MKBox>
                  <MKBox mb={4}>
                    <MKInput
                      id="password"
                      name="password"
                      type="password"
                      label="Senha"
                      required
                      fullWidth
                      error={getErr}
                    />
                  </MKBox>
                  <MKTypography component="h7" variant="h7" color="red">
                    {getErrMsg}
                  </MKTypography>
                  <MKBox mt={4} mb={1}>
                    <MKButton type="submit" variant="gradient" color="dark" fullWidth>
                      Entrar
                    </MKButton>
                  </MKBox>

                </MKBox>
              </MKBox>
              <MKTypography sx={{ mb: 4 }} variant="body2" color="text.secondary" align="center">
                {"Copyright © "}
                <Link color="inherit" href="https://www.codx.dev.br/">
                  CodX
                </Link>{" "}
                {new Date().getFullYear()}
                {"."}
              </MKTypography>
            </Card>
          </Grid>
        </Grid>
      </MKBox>
    </>
  );
}

export default Login;
