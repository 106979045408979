import * as React from "react";
import EditIcon from "@mui/icons-material/Edit";

import { Typography, Modal, Box, Button, Divider, IconButton, TextField } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  maxHeight: '90%', 
  overflowY: 'auto', 
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "20px",
};
export default function EditModal({ item , id, deleteItem, patchItem }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const payload = {
      description: data.get("name"),
      value: data.get("valor"),
    };

    patchItem(id, payload);

    return handleClose();
  };

  const handleDeleteItem = async () => {
    deleteItem(id);
    return handleClose();
  };

  return (
    <div>
      <IconButton aria-label="delete" size="large" onClick={handleOpen}>
        <EditIcon fontSize="inherit" />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" align="center">
            Editar Plano
          </Typography>
          <IconButton
            aria-label="fechar"
            size="large"
            onClick={handleClose}
            style={{ position: "absolute", top: "10px", right: "10px" }}
          >
            X
          </IconButton>
          <Divider />
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }} align="center">
            <Box component="div" sx={{ mt: 1, mb: 4 }} align="center">
              <TextField
                margin="normal"
                id="name"
                label="Nome"
                name="name"
                variant="standard"
                autoComplete="name"
                defaultValue={item.description}
                required
                fullWidth
                autoFocus
              />
            </Box>

            <Box component="div" sx={{ mt: 1, mb: 4 }}>
              <TextField margin="normal" id="valor" label="Valor" type="number" name="valor" variant="standard"
                defaultValue={item.value} required fullWidth />
            </Box>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 2, mb: 2, bgcolor: "primary.main" }}
            >
              Salvar
            </Button>
            <Button
              fullWidth
              variant="contained"
              color="error"
              sx={{ mt: 2, mb: 2 }}
              onClick={handleDeleteItem}
            >
              Deletar
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
