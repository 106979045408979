import { deleteTemplate, getTemplate, newTemplate, patchTemplate } from "../reqTemplate";

var ROUTE_URL = 'trainer';
var LOGIN_URL = 'auth/signup';

export const getAllTrainer = async () => {
  return await getTemplate(ROUTE_URL);
}; 

export const newTrainer = async (payload) => {
  return await  newTemplate(LOGIN_URL, payload)
};

export const deleteTrainer = async (id) => {
  return await deleteTemplate(`/${ROUTE_URL}/${id}`);
};

export const patchTrainer = async (id, payload) => {
  return await patchTemplate(`/${ROUTE_URL}/${id}`, payload);
};