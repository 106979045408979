import CryptoJS from "crypto-js";

const ENCRYPT_KEY = "QimU,u@m2T@VKlrG3C!x68;*lMiMn{*-<+=G,(SmS;@WaPjVs,~8*@-ia_Sy1#d";

export async function encryptCryptoJs(payload) {
  const valorCriptografado = CryptoJS.AES.encrypt(payload, ENCRYPT_KEY).toString();
  return valorCriptografado;
}

export async function decryptCryptoJs(payload) {
  const bytesDescriptografados = CryptoJS.AES.decrypt(payload, ENCRYPT_KEY);
  const valorDescriptografado = bytesDescriptografados.toString(CryptoJS.enc.Utf8);

  return valorDescriptografado;
}
