import * as React from "react";
import EditIcon from "@mui/icons-material/Edit";
import { Typography, Modal, Box, Button, Divider, IconButton, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useState, useEffect } from "react";
import CircularProgress from '@mui/material/CircularProgress';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "20px",
};

export default function EditModal({ item, id, deleteItem, patchItem, treinos, loadingTreinos }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [selectedTreinos, setSelectedTreinos] = useState(item.workouts || []);
  const [selectedTreinoIds, setSelectedTreinoIds] = useState([]);

  useEffect(() => {
    const ids = item.workouts?.map((treino) => treino.id) || [];
    setSelectedTreinoIds(ids);
    setSelectedTreinos(item.workouts || []);
  }, [item.workouts]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const payload = {
      description: data.get("name"),
      workouts: selectedTreinoIds,
    };
    console.log('payload', payload)
    patchItem(id, payload);
    handleClose();
  };

  const handleChange = (event, value) => {
    const selectedIds = value.map((treino) => treino.id);
    setSelectedTreinoIds(selectedIds);
    setSelectedTreinos(value);
  };

  const handleDeleteItem = async () => {
    deleteItem(id);
    handleClose();
  };

  return (
    <div>
      <IconButton aria-label="delete" size="large" onClick={handleOpen}>
        <EditIcon fontSize="inherit" />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" aclign="center">
            Editar Plano de Aula
          </Typography>
          <IconButton
            aria-label="fechar"
            size="large"
            onClick={handleClose}
            style={{ position: "absolute", top: "10px", right: "10px" }}
          >
            X
          </IconButton>
          <Divider />
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }} align="center">
            <Box component="div" sx={{ mt: 1, mb: 4 }} align="center">
              <TextField
                margin="normal"
                id="name"
                label="Nome"
                name="name"
                variant="standard"
                autoComplete="name"
                defaultValue={item.description}
                required
                fullWidth
                autoFocus
              />
            </Box>

            <Autocomplete
              multiple
              getOptionLabel={(option) => option.description}
              limitTags={2}
              options={treinos}
              value={selectedTreinos}
              onChange={handleChange}
              loading={loadingTreinos}
              disabled={loadingTreinos}
              noOptionsText={'Sem opções'}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              size='small'
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Treinos"
                  placeholder="+ Treino" 
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loadingTreinos && <CircularProgress color="inherit" size={20} />}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
                )}
              />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 2, mb: 2, bgcolor: "primary.main" }}
            >
              Editar
            </Button>
            <Button
              fullWidth
              variant="contained"
              color="error"
              sx={{ mt: 2, mb: 2 }}
              onClick={handleDeleteItem}
            >
              Deletar
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
