import React from 'react'

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Divider, Grid } from '@mui/material';

const ExerciseCard = ({ exercise, handleChange, expanded, base64Video}) => {

    console.log(exercise)

    return (

        <Accordion expanded={expanded === exercise.id} onChange={handleChange(exercise.id)} align="center">
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                sx={{ display: "flex", alignItems: "center" }}
            >
                {exercise.imageUrl && (
                    <img alt='img' src={exercise.imageUrl} style={{ maxWidth: "50px" }}></img>
                )}
                <Box sx={{ ml: 2 }}>

                    <Typography sx={{ flexShrink: 0 }}>
                        {exercise.description}
                    </Typography>
                </Box>

            </AccordionSummary>
            <Divider />
            <AccordionDetails>
                <Typography sx={{ flexShrink: 0, mb: 2 }}>
                    {exercise.execution}
                </Typography>
                {(base64Video.startsWith("data:image")) ? (
                    <img alt='gif' src={base64Video} style={{ width: "90%", mb: 3 }}></img>
                ) : (
                    <video src={base64Video} playsinline style={{ maxWidth: "80%",  mb: 3 }}
                        autoPlay={true} loop={true} muted={true}></video>
                )}
            </AccordionDetails>
        </Accordion>

    )
}

export default ExerciseCard
