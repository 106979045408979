// React
import React from 'react'

//Mui
import { AlertTitle, Stack, Alert } from '@mui/material';

//Router
import { Link } from 'react-router-dom';

const EmptyTreinosCard = () => {

  return (

    <Stack sx={{ width: '100%', boxShadow: 2, cursor:'pointer', borderRadius:'15px' }} spacing={2} mb={2}>
        <Link >
            <Alert variant="filled" color="primary" > 
                <AlertTitle> Treino Não cadastrado. </AlertTitle>
            </Alert>
        </Link>
    </Stack>

  )
}

export default EmptyTreinosCard
