import * as React from "react";
import SearchIcon from "@mui/icons-material/Search";
import { Grid, InputAdornment, TextField } from "@mui/material";
import PropTypes from "prop-types";

const SearchAppBar = ({ onChange, value }) => {
  const onType = (evento) => {
    onChange(evento.target.value);
  };

  return (
    <Grid container>
      <Grid sm={4} xs={12} sx={{ bgcolor: "#ffffff", borderRadius: 1 }}>
        <TextField
          onChange={onType}
          value={value}
          variant="outlined"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
};

SearchAppBar.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default SearchAppBar;
