import { deleteTemplate, getByIdTemplate, getTemplate, newTemplate, patchTemplate, postAutocompleteTemplate } from "../reqTemplate";

var ROUTE_URL = 'exercise';

export const getAllExercise = async () => {
  return await getTemplate(ROUTE_URL);
}; 

export const getAllExerciseAutocomplete = async () => {
  return await postAutocompleteTemplate(ROUTE_URL);
}; 

export const getExerciseById = async (id) => {
  return await getByIdTemplate(`/${ROUTE_URL}/${id}`);
};

export const newExercise = async (payload) => {
  console.log('newExercise', payload)
  return await newTemplate(ROUTE_URL, payload);
};

export const deleteExercise = async (id) => {
  return await deleteTemplate(`/${ROUTE_URL}/${id}`);
};

export const patchExercise = async (id, payload) => {
  return await patchTemplate(`/${ROUTE_URL}/${id}`, payload);
};
