import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { decryptCryptoJs } from "utils/crypto";

const PrivateRoute = ({ allowedRole }) => {

  const navigate = useNavigate();

  const getData = async (allowedRole) => {
  
    const encodedData = localStorage.getItem('encodedData');
  
    if (!encodedData){ return navigate("/login") } 
  
    const decodedData = await decryptCryptoJs(localStorage.getItem('encodedData'));
    const dataJson = JSON.parse(decodedData)

    if( dataJson.role === allowedRole ){ 
      return true
    } else {
      navigate("/login")
    }
   
    return false
  
  }

  getData(allowedRole);

  return <Outlet />;
};

export default PrivateRoute;
