// React 
import * as React from 'react';
import { useState } from 'react';
import { useEffect } from "react";

// Router
import { useNavigate } from 'react-router-dom';

// Mui
import { Box, Typography } from "@mui/material";

// Uses
import { decryptCryptoJs } from "utils/crypto";
import { getStudentById } from "api/crud/hooks/useAlunos";
import { getTrainingPlanById } from "api/crud/hooks/usePlanosAulas";

// Components
import EmptyTreinosCard from './EmptyTreinosCard';
import TreinosCard from './TreinosCard';
import SimpleBackdrop from 'components/SimpleBackdrop';

const TreinoAluno = () => {

  const navigate = useNavigate();
  
  const [studentData, setStudentData] = useState();
  const [workoutsData, setWorkoutsData] = useState([]);
  const [open, setOpen] = useState(true);

  useEffect(() => {
    getStudentData()
  }, [])

  const logout = () => {
    localStorage.clear();
    return navigate("/login");
  };

  const getStudentData = async () => {
    try {
      const localData = localStorage.getItem('encodedData')
      const EncodedData = await decryptCryptoJs(localData);
      const DecodedData = JSON.parse(EncodedData);
      const data = await getStudentById(DecodedData.id);
      setStudentData(data.message);
      if (data) {
        getTrainingData(data.message.trainingPlanId);
      } else {
        logout()
      }

    } catch {
      logout()
    }
  }

  const getTrainingData = async (training_plan_id) => {
    const data = await getTrainingPlanById(training_plan_id);
    setWorkoutsData(data.message.workouts)
    setOpen(false)
  }

  return (
    <>
      <SimpleBackdrop open={open} />
      <Box component="main" sx={{ flex: 1, py: 6, px: 4}}>

        {
          (studentData) && (
            <Typography sx={{ fontSize: '22px', mb: 4 }} align="center">
              {studentData.TrainingPlan ? studentData.TrainingPlan.description : 'Plano de Aula não cadastrado'}
            </Typography>
          )
        }

        {
          (workoutsData) ? (
            workoutsData.map((wkt) => (
              <TreinosCard workout={wkt} />
            ))
          ) : (
            <EmptyTreinosCard />
          )
        }

      </Box>
    </>
  );
};

export default TreinoAluno;

