import { deleteTemplate, getByIdTemplate, getTemplate, newTemplate, patchTemplate } from "../reqTemplate";

var ROUTE_URL = 'student';
var LOGIN_URL = 'auth/signup';


export const getAllStudent = async () => {
  return await getTemplate(ROUTE_URL);
}; 

export const deleteStudent = async (id) => {
  return await deleteTemplate(`/${ROUTE_URL}/${id}`);
};

export const getStudentById = async (id) => {
  return await getByIdTemplate(`/${ROUTE_URL}/${id}`);
};

export const newStudent = async (payload) => {

  const { body, url } = await constructStudentData(payload);

  const route = LOGIN_URL + url;

  return await newTemplate(route, body);
};

export const patchStudent = async (id, payload) => {

  const { body, url } = await constructStudentData(payload);

  const route = `${ROUTE_URL}/${id}${url}`

  return await patchTemplate(route, body);
};

const constructStudentData = async (payload) => {

  const {cpf, name, phoneNumber, password, email, impediments, planId, trainingPlanId} = payload;
  var body = '';
  if (!email && !impediments){
    body = { cpf, name, phoneNumber, password }
  } else if (!email){
    body = { cpf, name, phoneNumber, password, impediments }
  } else if (!impediments){
    body = { cpf, name, phoneNumber, password, email, impediments }
  } else {
    body = { cpf, name, phoneNumber, password, email, impediments }
  }

  const url = `?id_plan=${planId}&id_trainingPlan=${trainingPlanId}`

  return { body, url }
}


export const patchStudentChangePassword = async (id, payload) => {

  const { body, url } = await constructStudentChangePassword(payload);

  const route = `${ROUTE_URL}/${id}${url}`

  return await patchTemplate(route, body);
};

const constructStudentChangePassword = async (payload) => {

  const { password, planId, trainingPlanId} = payload;

  var body = { password }

  const url = `?id_plan=${planId}&id_trainingPlan=${trainingPlanId}`

  return { body, url }
}