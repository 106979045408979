/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import * as React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";

import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";

import { useState } from "react";

import { useEffect } from "react";
import { ItemCardTreinos as ItemCardObj } from "./ItemCardTreinos";

import { IconButton, TableCell, TablePagination, TableRow } from "@mui/material";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, alignContent: "right" }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const TableTreinos = ({
  itens,
  busca,
  EditModal,
  handleDeleteItem,
  handlePatchItem,
  treinos,
  loadingTreinos
}) => {
  const [page, setPage] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [count, setCount] = useState(0);

  const rowsFilter = itens
    ? itens.filter((filtro) => filtro.description.toLowerCase().includes(busca.toLowerCase()))
    : "";

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - itens.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  useEffect(() => {
    try {
      setCount(itens.length);
    } catch (e) {
      setCount(0);
    }
  });

  return (
    <>
      {count > 0 ? (
        (rowsPerPage > 0
          ? rowsFilter.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          : rowsFilter
        ).map((row) => (
          <ItemCardObj key={row.id} {...row}>
            <EditModal
              item={row}
              id={row.id}
              deleteItem={handleDeleteItem}
              patchItem={handlePatchItem}
              treinos={treinos}
              loadingTreinos={loadingTreinos}
            />
          </ItemCardObj>
        ))
      ) : (
        <ItemCardObj key="{row.id}" name={"Carregando ..."}></ItemCardObj>
      )}

      <Box
        sx={{
          flexShrink: 0,
          bgcolor: "white",
          borderRadius: "8px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box />
        {emptyRows > 0 && (
          <TableRow style={{ height: 53 * emptyRows }}>
            <TableCell colSpan={6} />
          </TableRow>
        )}
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[]}
            colSpan={3}
            count={rowsFilter.length}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              native: true,
            }}
            labelRowsPerPage="Paises por página"
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </TableRow>
      </Box>
    </>
  );
};

export default TableTreinos;
