import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Button, Divider, Grid, Snackbar, Stack, TextField, Typography } from "@mui/material";
// Uses
import { decryptCryptoJs } from "utils/crypto";
import { getStudentById } from "api/crud/hooks/useAlunos";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SimpleBackdrop from "components/SimpleBackdrop";
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  patchStudentChangePassword,
} from "api/crud/hooks/useAlunos";
import { encryptCryptoJs } from "utils/crypto";

const PerfilAluno = () => {

  const navigate = useNavigate();

  const [studentData, setStudentData] = useState();
  const [studentLogin, setStudentLogin] = useState();
  const [open, setOpen] = useState(true);
  const [ErrorPassword, setErrorPassword] = useState();
  const [ErrorCurrentPassword, setErrorCurrentPassword] = useState();

  const [openSuccess, setOpenSuccess] = useState(false);
  const [openText, setOpenText] = React.useState("");
  const [openErr, setOpenErr] = React.useState(false);
  const [openErrText, setOpenErrText] = React.useState("");
  
  const [senhaNova, setSenhaNova] = React.useState("");

  const [expandedChangePass, setExpandedChangePass] = useState(false);

  const handleClose = () => setOpenSuccess(false);
  const handleCloseErr = () => setOpenErr(false);

  useEffect(() => {
    getStudentData()
  }, [])

  const getStudentData = async () => {
    try {
      const localData = localStorage.getItem('encodedData')
      const localLogin = localStorage.getItem('encodedLogin')

      const EncodedData = await decryptCryptoJs(localData);
      const EncodedLogin = await decryptCryptoJs(localLogin);

      const DecodedData = JSON.parse(EncodedData);
      const DecodedLogin = JSON.parse(EncodedLogin);

      const data = await getStudentById(DecodedData.id);

      setStudentData(data.message);
      setStudentLogin(DecodedLogin);

      setOpen(false)
      if (!data) {
        logout()
      }

    } catch {
      logout()
    }
  }

  const handleChange = (exerciseId) => (event, isExpanded) => {
    setExpandedChangePass(!expandedChangePass)
  };

  const changePassword = (event) => {

    event.preventDefault();
    const data = new FormData(event.currentTarget);

    setErrorCurrentPassword()
    setErrorPassword()

    if (data.get("senha_atual") !== studentLogin.password) {
      console.log('Senha atual incorreta.')
      setErrorCurrentPassword('Senha atual incorreta.')
      return true;
    }

    if (data.get("senha1") !== data.get("senha2")) {
      console.log('Senhas não são iguais.')
      setErrorPassword('Senhas não são iguais.')
      return true;
    }

    if (data.get("senha1").length < 6) {
      console.log('Senha deve conter mais de 6 dígitos.')
      setErrorPassword('Senha deve conter mais de 6 dígitos.')
      return true;
    }

    setSenhaNova(data.get("senha1"))

    const payload = {
      password: data.get("senha1"),
      planId: studentData.planId,
      trainingPlanId: studentData.trainingPlanId
    };

    console.log(studentData)
    console.log(payload)


    console.log('trocou')
    PatchFunction(payload);

    return true;

  };


  const PatchFunction = async (payload) => {
    setOpen(true)
    try {
      const { status } = await patchStudentChangePassword(studentData.id, payload);
      setOpen(false)
      setRes(status);
    } catch (error) {
      console.error(error);
    }
  };

  const setRes = async (status) => {
    if (status === 200) {

      const payload = {
        cpf: studentLogin.cpf,
        password: senhaNova,
      }

      const resLoginPayload = JSON.stringify(payload);
      const encryptLogin = await encryptCryptoJs(resLoginPayload);
      localStorage.setItem("encodedLogin", encryptLogin);
      handleChange()
      setOpenText('Senha Alterada com sucesso!');
      setOpenSuccess(true);
    } else {
      setOpenErrText('Erro ao alterar Senha!');
      setOpenErr(true);
    }
  }


  const logout = () => {
    localStorage.clear();
    return navigate("/login");
  };

  return (
    <>
      <SimpleBackdrop open={open} />
      {(!open) && (


        <Box component="main" sx={{ flex: 1, py: 6, px: 4 }} mt={3}>
          <Box align='center' mb={2}>
            <h1>Bem vindo {studentData.name} 💪 </h1>
          </Box>
          <Divider />
          <Box mt={2}>
            {(studentData.TrainingPlan) ?
              (
                <h2>Seu Plano de Aula é :  {studentData.TrainingPlan.description}. </h2>
              ) : (
                <h2>Não encontramos seu Plano de Aula !</h2>
              )
            }
          </Box>
          {(!open) && (
            <Grid item xs={12} sm={12}>
              <Stack sx={{ boxShadow: 2, mb: 2, marginX: 1, mt: 4, borderRadius: '15px' }} >
                <Button variant="contained" size="md" startIcon={<MeetingRoomIcon />} onClick={logout} spacing={2} sx={{ spacing: 2, '&:hover': { backgroundColor: '#333' } }}>
                  Sair
                </Button>
              </Stack>
            </Grid>
          )}

          <Accordion expanded={expandedChangePass} onChange={handleChange()} align="center">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              sx={{ display: "flex", alignItems: "center" }}
            >

              <Box sx={{ ml: 2 }}>

                <Typography sx={{ flexShrink: 0 }}>
                  Alterar Senha
                </Typography>
              </Box>

            </AccordionSummary>
            <Divider />
            <AccordionDetails>
              <Box sx={{ mb: 1 }} component="form" onSubmit={changePassword}  >

                <Box sx={{ mb: 1 }}>
                  <TextField margin="normal" id="senha_atual" label="Senha Atual" name="senha_atual" type="password"
                    helperText={ErrorCurrentPassword} error={ErrorCurrentPassword ? true : false} fullWidth />
                </Box>

                <Box sx={{ mb: 1 }}>
                  <TextField margin="normal" id="senha1" label="Nova Senha" name="senha1" type="password" required
                    helperText={ErrorPassword} error={ErrorPassword ? true : false} fullWidth />
                </Box>

                <Box sx={{ mb: 3 }}>
                  <TextField margin="normal" id="senha2" label="Repita a Senha" name="senha2" type="password" required
                    helperText={ErrorPassword} error={ErrorPassword ? true : false} fullWidth />
                </Box>

                <Box sx={{ mb: 1 }}>
                  <Button variant="contained" fullWidth type="submit"
                    sx={{ spacing: 2, '&:hover': { backgroundColor: '#333' } }} >
                    Alterar Senha
                  </Button>
                </Box>

              </Box>
            </AccordionDetails>
          </Accordion>

          <Snackbar open={openSuccess} autoHideDuration={3000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
              {openText}
            </Alert>
          </Snackbar>
          <Snackbar open={openErr} autoHideDuration={3000} onClose={handleCloseErr}>
            <Alert onClose={handleCloseErr} severity="error" sx={{ width: "100%" }}>
              {openErrText}
            </Alert>
          </Snackbar>


        </Box>
      )}
    </>
  );
};

export default PerfilAluno;
