import * as React from "react";
import PropTypes from "prop-types";

import EditIcon from "@mui/icons-material/Edit";
import UploadIcon from "@mui/icons-material/Upload";

import { Typography, Modal, Box, Button, Divider, IconButton, TextField } from "@mui/material";
import { useState } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  maxHeight: '90%', 
  overflowY: 'auto', 
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "20px",
};

export default function EditModal({ item , id, deleteItem, patchItem }) {
  console.log('item', item)
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [base64Image, setBase64Image] = useState("");

  React.useEffect(() => {
    setBase64Image(item.imageUrl);
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    
    const payload = {
      title: data.get("titulo"),
      content: data.get("conteudo"),
      imageUrl: base64Image,
    };

    console.log('payload', payload)

    patchItem(id, payload);

    return handleClose();
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result;
        setBase64Image(base64String);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleDeleteItem = async () => {
    deleteItem(id);
    return handleClose();
  };

  return (
    <div>
      <IconButton aria-label="delete" size="large" onClick={handleOpen}>
        <EditIcon fontSize="inherit" />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" align="center">
            Editar Exercicio
          </Typography>
          <IconButton
            aria-label="fechar"
            size="large"
            onClick={handleClose}
            style={{ position: "absolute", top: "10px", right: "10px" }}
          >
            X
          </IconButton>
          <Divider />
          
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }} align="center">

            <Box component="div" sx={{ mt: 1, mb: 1 }}>
              <TextField defaultValue={item.title} margin="normal" id="titulo" label="Título" name="titulo" variant="standard" autoComplete="titulo" required fullWidth autoFocus />
            </Box>
            <Box component="div" sx={{ mt: 1, mb: 1 }}>
              <TextField defaultValue={item.content} margin="normal" id="conteudo" label="Conteúdo" name="conteudo" variant="standard" autoComplete="conteudo" required fullWidth />
            </Box>


            <Button
              startIcon={<UploadIcon />}
              variant="outlined"
              component="label"
              color="inherit"
              fullWidth
              sx={{ mt: 1, mb: 4 }}
            >
              Alterar Mídia
              <input type="file" onChange={handleImageUpload} hidden />
            </Button>
           
            <Box align="center">
            {base64Image && (base64Image.startsWith("data:image")) ? (
              <img alt='gif' src={base64Image} style={{ maxWidth: "300px", mb: 3 }}></img>
            ) : (
              <video src={base64Image}  playsinline style={{ maxWidth: "300px", mb: 3 }} 
                autoPlay={true} loop={true} muted={true}></video>
            )}
              
            </Box>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 2, mb: 2, bgcolor: "primary.main" }}
            >
              Salvar
            </Button>
            <Button
              fullWidth
              variant="contained"
              color="error"
              sx={{ mt: 2, mb: 2 }}
              onClick={handleDeleteItem}
            >
              Deletar
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

EditModal.propTypes = {
  name: PropTypes.string.isRequired,
  image: PropTypes.string,
  id: PropTypes.number.isRequired,
  deleteItem: PropTypes.func.isRequired,
  patchItem: PropTypes.func.isRequired,
};
