/* eslint-disable no-unused-vars */
import { Alert, Box, Snackbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import HeaderPageDefault from "pages/admin/components/HeaderPageDefault";
import ItemCardDefault from "pages/admin/components/ItemCardDefault";
import EditModal from "./components/EditModal";
import NewModal from "./components/NewModal";
import SearchAppBar from "components/CODXSearchAppBar/SearchAppBar";
import CustomPaginationActionsTable from "components/CODXTablePagination/TablePaginationActions";
import {
  getAllStudent,
  newStudent,
  deleteStudent,
  patchStudent,
} from "api/crud/hooks/useAlunos";
import HeaderAlunos from "./components/HeaderAlunos";
import { getAllWorkout } from "api/crud/hooks/useTreinos";
import { getAllPlan } from "api/crud/hooks/usePlanos";
import { getAllTrainingPlan } from "api/crud/hooks/usePlanosAulas";
import TableAlunos from "./components/TableAlunos";
import SimpleBackdrop from "components/SimpleBackdrop";
import EmptyCard from "pages/admin/components/EmptyCard";

const PAGE_NAME = "Alunos";

const Alunos = () => {
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openText, setOpenText] = React.useState("");
  const [openErr, setOpenErr] = React.useState(false);
  const [openErrText, setOpenErrText] = React.useState("");
  const [busca, setBusca] = useState("");
  const [items, setItems] = useState([]);
  const [open, setOpen] = useState(true);

  const handleClose = () => setOpenSuccess(false);
  const handleCloseErr = () => setOpenErr(false);

  const [planos, setPlanos] = useState([]);
  const [treinos, setTreinos] = useState([]);

  useEffect(() => {
    GetItens();
    GetPlanos();
    GetTrainingPlans();
  }, []);

  const GetTrainingPlans = async () => {
    try {
      const { status, message } = await getAllTrainingPlan();
      if (status === 200) {
        setTreinos(message);
      } else {
        setTreinos([]);
      }
    } catch (error) {
      console.error('error GetGruposMusculares', error);
    }
  };

  const GetPlanos = async () => {
    try {
      const { status, message } = await getAllPlan();
      if (status === 200) {
        setPlanos(message);
      } else {
        setPlanos([]);
      }
    } catch (error) {
      console.error('error GetGruposMusculares', error);
    }
  };

  const GetItens = async () => {
    try {
      const { status, message } = await getAllStudent();
      console.log('GetItens', status, message)
      setOpen(false)
      if (status === 200) {
        setItems(message);
      } else {
        setItems([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const AddNewItem = async (payload) => {
    setOpen(true)
    try {
      const { status, message } = await newStudent(payload);
      setRes(status, message);
    } catch (error) {
      console.error(error);
    }
    GetItens();
  };

  const handleDeleteItem = async (id) => {
    setOpen(true)
    try {
      const { status, message } = await deleteStudent(id);
      setRes(status, message);
    } catch (error) {
      console.error(error);
    }
    GetItens();
  };

  const handlePatchItem = async (id, payload) => {
    setOpen(true)
    try {
      const { status, message } = await patchStudent(id, payload);
      setRes(status, message);
    } catch (error) {
      console.error(error);
    }
    GetItens();
  };

  const setRes = (status, message) => {
    if (status === 200) {
      setOpenText(message);
      setOpenSuccess(true);
    } else {
      setOpenErrText(message);
      setOpenErr(true);
    }
  }

  return (
    <>

      <SimpleBackdrop open={open} />
      <HeaderAlunos addFunction={AddNewItem} tittle={PAGE_NAME} NewModal={NewModal} planos={planos} treinos={treinos} />
      <Box component="main" sx={{ flex: 1, py: 6, px: 4, bgcolor: "#f2f2f2" }}>
        <SearchAppBar
          value={busca}
          onChange={(caracter) => {
            setBusca(caracter);
          }}
        />
        <br />
        {items ? (
          <TableAlunos
            itens={items}
            busca={busca}
            EditModal={EditModal}
            handleDeleteItem={handleDeleteItem}
            handlePatchItem={handlePatchItem}
            planos={planos} treinos={treinos}
          />
        ) : (
          <EmptyCard nome="Sem Alunos." />
        )}
      </Box>
      <Snackbar open={openSuccess} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          {openText}
        </Alert>
      </Snackbar>
      <Snackbar open={openErr} autoHideDuration={3000} onClose={handleCloseErr}>
        <Alert onClose={handleCloseErr} severity="error" sx={{ width: "100%" }}>
          {openErrText}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Alunos;
