import * as React from "react";
import { Divider, Typography, Modal, Box, Button, TextField, FormControl, InputLabel, Select, MenuItem, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import UploadIcon from "@mui/icons-material/Upload";
import { useState } from "react";
import { CpfValidator } from "utils/cpfValidator";
import MaskedInput from "react-text-mask";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  maxHeight: '90%',
  overflowY: 'auto',
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "20px",
};

const cpfMask = [/[0-9]/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/,];

export default function NewModal({ planos, treinos, addFunction }) {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const lightColor = "rgba(255, 255, 255, 0.7)";

  const [treino, setTreino] = useState();
  const [plano, setPlano] = useState();
  const [ErrorMessage, setErrorMessage] = useState();

  const handleOpen = () => {
    setTreino();
    setPlano();
    setOpen(true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const correctCPF = await validateDatas(data);
    if (correctCPF) {

      const payload = {
        cpf: removerMascara(data.get("cpf")),
        name: data.get("name"),
        phoneNumber: data.get("telefone"),
        password: data.get("senha"),
        email: data.get("email"),
        impediments: data.get("impedimentos"),
        trainingPlanId: treino,
        planId: plano,
      };
      console.log('payload', payload)
      addFunction(payload);
      return handleClose();
    }
    return true
  };

  const changeTreino = (event) => {
    setTreino(event.target.value);
  };

  const changePlano = (event) => {
    setPlano(event.target.value);
  };

  function removerMascara(cpfMascarado) {
    return cpfMascarado.replace(/\D/g, '');
  }

  const validateDatas = async (data) => {
    var res = await CpfValidator(data.get('cpf'));

    if (res) {

      setErrorMessage()
      return true;

    } else {
      setErrorMessage("CPF inválido")
      return false

    }
  }


  return (
    <div>
      <Button
        variant="outlined"
        color="inherit"
        sx={{ borderColor: lightColor }}
        startIcon={<AddIcon />}
        onClick={handleOpen}
        spacing={2}
      >
        Adicionar
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" align="center">
            Adicionar Alunos
          </Typography>
          <IconButton
            aria-label="fechar"
            size="large"
            onClick={handleClose}
            style={{ position: "absolute", top: "10px", right: "10px" }}
          >
            X
          </IconButton>
          <Divider />
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }} align="center">
            <Box component="div" sx={{ mt: 1 }}>
              <TextField margin="normal" id="name" label="Nome" name="name" variant="outlined"
                required fullWidth autoFocus />
              <MaskedInput mask={cpfMask} label={'CPF'} name="cpf"
                guide={false}
                render={(ref, props) => (
                  <TextField variant="outlined" required fullWidth inputRef={ref} {...props} helperText={ErrorMessage} error={ErrorMessage ? true : false} />
                )}
              />
              <TextField margin="normal" id="telefone" label="Telefone" name="telefone" variant="outlined"
                required fullWidth />
              <TextField margin="normal" id="email" label="Email" name="email" variant="outlined"
                fullWidth />
              <TextField margin="normal" id="name" label="Impedimentos" name="impedimentos" variant="outlined"
                fullWidth />
              <TextField margin="normal" type="password" id="senha" label="Senha" name="senha" variant="outlined"
                required fullWidth />
            </Box>
            <FormControl fullWidth margin="normal">
              <InputLabel>Plano de Aula</InputLabel>
              <Select
                onChange={changeTreino}
                value={treino}
                label='Plano de Aula'
                required
              >
                {treinos && treinos.map(grupo => (
                  <MenuItem value={grupo.id} key={grupo.id}>
                    {grupo.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth margin="normal">
              <InputLabel>Planos</InputLabel>
              <Select
                onChange={changePlano}
                value={plano}
                label='Planos'
                required
              >
                {planos && planos.map(grupo => (
                  <MenuItem value={grupo.id} key={grupo.id}>
                    {grupo.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 2, mb: 2, bgcolor: "primary.main" }}
            >
              Adicionar
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

