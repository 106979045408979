export const CpfValidator = async (numerosDoCPF) => {

    // Remover pontos e traço
    const cpf = numerosDoCPF.replace(/\D/g, '');

    if (!/^\d{11}$/.test(cpf)) {
        return false;
    }

    // Verificar CPFs com todos os dígitos iguais
    if (/^(\d)\1{10}$/.test(cpf)) {
        return false;
    }

    // Calcular os dígitos verificadores
    const digits = cpf.split('').map(Number);
    const [a, b, c, d, e, f, g, h, i] = digits;

    const validateDigit = (numeros, peso) => {
        const total = numeros.reduce((acc, numero, index) => {
            return acc + numero * (peso - index);
        }, 0);
        const resto = total % 11;
        return resto < 2 ? 0 : 11 - resto;
    };

    const calculatedA = validateDigit([a, b, c, d, e, f, g, h, i], 10);
    const calculatedB = validateDigit([a, b, c, d, e, f, g, h, i, calculatedA], 11);

    return cpf === [a, b, c, d, e, f, g, h, i, calculatedA, calculatedB].join('');
    
};