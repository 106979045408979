import * as React from "react";
import { Typography, Modal, Box, Button, Divider, IconButton, TextField, FormControl, InputLabel, Select, MenuItem, Autocomplete } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CircularProgress from '@mui/material/CircularProgress';
import { useState } from "react";
import { useEffect } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  maxHeight: '90%', 
  overflowY: 'auto', 
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "20px",
};

export default function EditModal({ item , id, deleteItem, patchItem, exercicios, loadingExercicios }) {
  const [open, setOpen] = React.useState(false);

  const [selectedExercises, setSelectedExercises] = useState(item.exercises || []);
  const [selectedExerciseIds, setSelectedExerciseIds] = useState([]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  console.log('dentro editModal')
  console.log(item);
  console.log(exercicios);

  useEffect(() => {
    const ids = item.exercises?.map((exercise) => exercise.id) || [];
    setSelectedExerciseIds(ids);
    setSelectedExercises(item.exercises || []);
  }, [item.exercises]);

  const handleChange = (event, value) => {
    const selectedIds = value.map((treino) => treino.id);
    setSelectedExerciseIds(selectedIds);
    setSelectedExercises(value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const payload = {
      description: data.get("name"),
      exercises: selectedExerciseIds,
    };

    patchItem(id, payload);

    return handleClose();
  };

  const handleDeleteItem = async () => {
    deleteItem(id);
    return handleClose();
  };

  return (
    <div>
      <IconButton aria-label="delete" size="large" onClick={handleOpen}>
        <EditIcon fontSize="inherit" />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" aclign="center">
            Editar Treino
          </Typography>
          <IconButton
            aria-label="fechar"
            size="large"
            onClick={handleClose}
            style={{ position: "absolute", top: "10px", right: "10px" }}
          >
            X
          </IconButton>
          <Divider />
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }} align="center">
            <Box component="div" sx={{ mt: 1, mb: 4 }} align="center">
              <TextField
                margin="normal"
                id="name"
                label="Nome"
                name="name"
                variant="standard"
                autoComplete="name"
                defaultValue={item.description}
                required
                fullWidth
                autoFocus
              />
            </Box>

            <Autocomplete
              multiple
              getOptionLabel={(option) => option.description}
              limitTags={2}
              options={exercicios}
              value={selectedExercises}
              onChange={handleChange}
              loading={loadingExercicios}
              disabled={loadingExercicios}
              noOptionsText={'Sem opções'}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              size='small'
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Exercícios"
                  placeholder="+ Exercícios" 
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loadingExercicios && <CircularProgress color="inherit" size={20} />}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
            )}
              />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 2, mb: 2, bgcolor: "primary.main" }}
            >
              Salvar
            </Button>
            <Button
              fullWidth
              variant="contained"
              color="error"
              sx={{ mt: 2, mb: 2 }}
              onClick={handleDeleteItem}
            >
              Deletar
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
