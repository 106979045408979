import { Box } from "@mui/material";
import HeaderPageDefault from "pages/admin/components/HeaderPageDefault";
import React from "react";

const Painel = () => {
  return (
    <>
      <HeaderPageDefault tittle="Painel" />
      <Box component="main" sx={{ flex: 1, py: 6, px: 4, bgcolor: "#f2f2f2" }}>
        <h1>Lista de Dashboards</h1>
      </Box>
    </>
  );
};

export default Painel;
